import React, { useEffect, useState } from 'react'
import ExploreItems from '../explore/explore-items';
import './about-nfts.css';
const AboutNfts = (props) => {
    const [viewAll, setViewAll] = useState(false);
    const [x, setX] = useState(false)

    const getViewAll = (view) => {
        setViewAll(view)
        if (props.setLoader)
            props.setLoader(false)
    }

    const handleViewMore = () => {
        props.setKey(props.keyValue)
    }

    return (
        <div className='about-nfts'>
            <div className='top-collection'>
                <div className='view-section d-flex justify-content-between align-items-center'>
                    <div
                        className="about-head"
                    >
                        Top Items
                    </div>
                </div>
                <div className='top-items mb-4'>
                    {
                        // !x &&
                        <ExploreItems key="exo"
                            mostSold={true} limit={4}
                            loadMore={false} p={1} setX={setX}
                            setLoader={props.setLoader}
                            {...(props?.creatorId ? { creatorId: props?.creatorId } : {})}  //if creatorId exist then props send otherWise Not
                            {...(props?.collectionId ? { collectionId: props?.collectionId } : {})}  //if CollectionId exist then props send otherWise Not
                        />
                    }
                </div>
                <div className="view-button text-right">
                    <button className='gradient-button btn'
                        onClick={handleViewMore}
                    >View More</button>
                </div>
            </div>
            <div className='sold-collection'>
                <div className='view-section'>
                    <div className="about-head">
                        Previously Sold
                    </div>
                </div>
                <div className='top-items'>
                    {
                        x &&
                        <ExploreItems
                            key="exo1"
                            previouslySold={true}
                            limit={4}
                            setView={true}
                            setViewAll={getViewAll}
                            loadMore={false} p={2}
                            setLoader={props.setLoader}
                            {...(props?.creatorId ? { creatorId: props?.creatorId } : {})}
                            {...(props?.collectionId ? { collectionId: props?.collectionId } : {})}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default AboutNfts