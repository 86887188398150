import { BEFORE_LISTING, GET_LISTINGS, CANCEL_LISTING } from '../../redux/types'

const initialState = {
    listing: null,
    listings: null,
    pagination: null,
    cancelAuth: false,
    getAuth: false
}

export default function listingsRed(state = initialState, action) {
    switch (action.type) {
        case GET_LISTINGS:
            return {
                ...state,
                listings: action.payload.listings,
                pagination: action.payload.pagination,
                getAuth: true
            }
        case CANCEL_LISTING:
            return {
                ...state,
                listing: action.payload,
                cancelAuth: true
            }
        case BEFORE_LISTING:
            return {
                ...state,
                listing: null,
                listings: null,
                pagination: null,
                cancelAuth: false,
                getAuth: false,
            }
        default:
            return {
                ...state
            }
    }
}