// ERRORS
export const EMPTY_ERRORS = "EMPTY_ERRORS";
export const GET_ERRORS = "GET_ERRORS";

// AUCTIONS
export const BEFORE_AUCTION = "BEFORE_AUCTION";
export const GET_AUCTIONS = "GET_AUCTIONS";

// NFTS
export const BEFORE_NFT = "BEFORE_NFT";
export const GET_NFTS = "GET_NFTS";
export const GET_NFT = "GET_NFT";
export const UPSERT_NFT = "UPSERT_NFT";
export const SEARCH_NFT = "SEARCH_NFT";
export const BEFORE_METADATA = "BEFORE_METADATA";
export const REFRESH_METADATA = "REFRESH_METADATA";
export const TRANSFER_NFT = "TRANSFER NFT";
export const BUY_NFT = "BUY_NFT";
export const BEFORE_BUY_NFT = "BEFORE_BUY_NFT";

// FAVOURITE
export const SET_FAVOURITE = "SET_FAVOURITE";

// WALLET
export const BEFORE_WALLET = "BEFORE_WALLET";
export const SET_WALLET = "SET_WALLET";
export const GET_WALLET = "GET_WALLET";
export const SET_WALLET_ERROR = "SET_WALLET_ERROR";
export const REDIRECT_TO_WALLET = "REDIRECT_TO_WALLET";

// USERS
export const BEFORE_USER = "BEFORE_USER";
export const SET_USER = "SET_USER";
export const GET_USER = "GET_USER";
export const SET_CREATORS = "SET_CREATORS";
export const TOP_SELLERS = "TOP_SELLERS";
export const SET_INDIVIDUAL_USER = "SET_INDIVIDUAL_USER";
export const SET_BANNER = "SET_BANNER";
export const USER_TRADE = "USER_TRADE";
export const REGISTER_First = "REGISTER_First";

//CHAT
export const SET_CHAT_LIST = "SET_CHAT_LIST";

//HOMEPAGE
export const SET_BANNER_NFT = "SET_BANNER_NFT";
export const SET_LOGIN_PAGE_NFT = "SET_LOGIN_PAGE_NFT";
export const BEFORE_HOME = "BEFORE_HOME";
export const SET_NOTABLE_COLLECTIONS = "SET_NOTABLE_COLLECTIONS";
export const SET_TOP_COLLECTIONS = "SET_TOP_COLLECTIONS";
export const SET_MOST_SOLD = "SET_MOST_SOLD";
export const SET_TOP_CATEGORY = "SET_TOP_CATEGORY";
export const SET_STATS = "SET_STATS";

// FOLLOW
export const SET_FOLLOW = "SET_FOLLOW";
export const SET_FOLLOWERS = "SET_FOLLOWERS";
export const SET_FOLLOWINGS = "SET_FOLLOWINGS";
export const BEFORE_FOLLOWING = "BEFORE_FOLLOWING";
export const BEFORE_FOLLOWER = "BEFORE_FOLLOWER";

// NOTIFICATIONS
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_NOTIFICATIONS_COUNT = "SET_NOTIFICATIONS_COUNT";
export const SET_UNREAD_COUNT = "SET_UNREAD_COUNT";
export const UPDATE_UNREAD_COUNT = "UPDATE_UNREAD_COUNT";
export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS";
export const UPDATE_NOTI_COUNT = "UPDATE_NOTI_COUNT";

// CATEGORIES
export const BEFORE_CATEGORY = "BEFORE_CATEGORY";
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_CATEGORIES = "GET_CATEGORIES";

// COLLECTION
export const BEFORE_COLLECTION = "BEFORE_COLLECTION";
export const GET_COLLECTION = "GET_COLLECTION";
export const UPSERT_COLLECTION = "UPSERT_COLLECTION";
export const DELETE_COLLECTION = "DELETE_COLLECTION";
export const GET_COLLECTIONS = "GET_COLLECTIONS";
export const SET_LANDING_COLLECTIONS = "SET_LANDING_COLLECTIONS";

// FOOTER
export const BEFORE_FOOTER = "BEFORE_FOOTER";
export const GET_FOOTER = "GET_FOOTER";

// FAQ
export const BEFORE_FAQ = "BEFORE_FAQ";
export const GET_FAQS = "GET_FAQS";
export const GET_FAQ = "GET_FAQ";

// OFFER
export const BEFORE_OFFER = "BEFORE_OFFER";
export const GET_OFFERS = "GET_OFFERS";
export const DELETE_OFFER = "DELETE_OFFER";
export const CREATE_OFFER = "CREATE_OFFER";
export const ACCEPT_OFFER = "ACCEPT_OFFER";

// BID
export const BEFORE_BID = "BEFORE_BID";
export const GET_BIDS = "GET_BIDS";
export const DELETE_BID = "DELETE_BID";
export const CREATE_BID = "CREATE_BID";
export const ACCEPT_BID = "ACCEPT_BID";

// LISTING
export const BEFORE_LISTING = "BEFORE_LISTING";
export const CANCEL_LISTING = "CANCEL_LISTING";
export const BEFORE_CANCEL_LISTING = "BEFORE_CANCEL_LISTING";
export const GET_LISTINGS = "GET_LISTINGS";
export const BUY_LISTING = "BUY_LISTING";

// NAVBAR
export const BEFORE_NAVBAR = "BEFORE_BAR";
export const GET_NAVBAR = "GET_NAVBAR";

// EXPLORE
export const BEFORE_EXPLORE = "BEFORE_EXPLORE";
export const GET_EXPLORE = "GET_EXPLORE";
export const SET_EXPLORE_ID = "SET_EXPLORE_ID";
export const SET_ALL = "SET_ALL";
export const BEFORE_ALL = "BEFORE_ALL";
export const BEFORE_SET_ID = "BEFORE_SET_ID";

// CONTACT
export const BEFORE_CONTACT = "BEFORE_CONTACT";
export const CREATE_CONTACT = "CREATE_CONTACT";

// SETTINGS
export const BEFORE_SETTINGS = "BEFORE_SETTINGS";
export const GET_SETTINGS = "GET_SETTINGS";
export const SET_SUBSCRIBE = "SET_SUBSCRIBE";
export const SET_SEND_EMAIL = "SET_SEND_EMAIL";
export const SET_VERIFY_EMAIL = "SET_VERIFY_EMAIL";
export const GET_TOKEN = "GET_TOKEN";

export const THEME_SETTINGS = "THEME_SETTINGS";
export const BEFORE_THEME_SETTINGS = "BEFORE_THEME_SETTINGS";
// DISCOVER
export const BEFORE_DISCOVER = "BEFORE_DISCOVER";
export const GET_DISCOVER_NAV = "GET_DISCOVER_NAV";

// ACTIVITY
export const BEFORE_ACTIVITY = "BEFORE_ACTIVITY";
export const GET_ACTIVITIES = "GET_ACTIVITIES";

// APP
export const BEFORE_APP = "BEFORE_APP";
export const SET_RATES = "SET_RATES";

// BANNER
export const BEFORE_BANNER = "BEFORE_BANNER";
export const GET_BANNER = "GET_BANNER";

// HISTORY
export const GET_HISTORY = "GET_HISTORY";
export const BEFORE_HISTORY = "BEFORE_HISTORY";

// ATTRIBUTES
export const BEFORE_ATTRIBUTES = "BEFORE_ATTRIBUTES";
export const GET_ATTRIBUTES = "GET_ATTRIBUTES";

//ORDERS
export const BEFORE_ORDERS = "BEFORE_ORDERS";
export const GET_ORDERS = "GET_ORDERS";
export const UPSERT_ORDER = "UPSERT_ORDER";
