/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { beforeNFT, getNFTs, favoriteToggle } from '../nfts/nfts.action';
import { ENV } from '../../config/config';
import FullPageLoader from '../full-page-loader/full-page-loader'
import { Link, useLocation } from 'react-router-dom';
import FilterSearch from "../filter-search/filtersearch";
import YellowChecked from '../../assets/images/tik.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faHeart, faArrowRotateRight } from '@fortawesome/free-solid-svg-icons'
import SearchFilters from '../search-filters/search-filters';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap"
import Ethereum from '../../assets/images/arow-icon.png';
import Binance from '../../assets/images/bnb.png';
import Fantom from '../../assets/images/fantom.png';
import Cronos from '../../assets/images/cronos.png';


const initData = {
    pre_heading: "Explore",
    heading: "Explore NFTs",
    // content: "Explore the NFTs by professional filter options and top trending.",
    btn_1: "Load More"
}

function ExploreAll(props) {
    const [nftPagination, setNFTPagination] = useState(null);
    const [nfts, setNFTs] = useState(null); // NFTs for explore section
    const [loader, setLoader] = useState(true);
    const [loadMoreBtn, setLoadMoreBtn] = useState(false);
    const [isMoreClicked, setLoadMore] = useState(false); // flag to check if load more button clicked
    const [intro, setIntro] = useState(false);
    const [searchAndFilters, setSearchAndFilters] = useState(false);
    const [applyFilterData, setApplyFilterData] = useState(false);
    const [resetFilters, setResetFilters] = useState(false);
    const [nftFilter, setNftFitler] = useState(null);
    const { pathname } = useLocation();
    const [Filter, setFilter] = useState(false);  // flag for responsive view only
    const [showFilter, setShowFilter] = useState(true);
    const { width } = useWindowSize() || {};
    const navigate = useNavigate();
    const userId = ENV.getUserKeys()?._id ? ENV.getUserKeys()?._id : undefined

    useEffect(() => {
        if (width <= 991 || props.draft) {
            setShowFilter(false);
        } else {
            setShowFilter(true);
        }
    }, [width]);

    useEffect(() => {
        window.scroll(0, 0)
        // set intro section
        if (pathname === '/explore-all') {
            setIntro(true)
            setSearchAndFilters(true)
        }
        // get NFTs for explore section      
        setLoader(true)
        getNFTs()
    }, [props.blockChain, props.categoryId]) // eslint-disable-line react-hooks/exhaustive-deps

    
    useEffect(() => {
        if (props.nft.nftsAuth) {
            const { nftsData } = props.nft
            if (nftsData) {
                setNFTs(isMoreClicked ? nfts.concat(nftsData.nfts) : nftsData.nfts)
                setNFTPagination(nftsData.pagination)
                if (nftsData.nfts?.length) {
                    setLoadMoreBtn(true)
                }
                if (nftsData.pagination && nftsData.pagination.page === nftsData.pagination.pages) {
                    setLoadMoreBtn(false)
                    if (props.setCollectedNfts) {
                        props.setCollectedNfts(nftsData.pagination.total)
                        props.setCreatedNfts(nftsData.createdNfts)
                    } else if (props.setCreatedNfts)
                        props.setCreatedNfts(nftsData.pagination.total)
                }
                props.beforeNFT()
                setLoader(false)
                setLoadMore(false)
                if (props.setLoader)
                    props.setLoader(false)
            }
        }
    }, [props.nft.nftsAuth]) // eslint-disable-line react-hooks/exhaustive-deps

    const loadMore = () => {
        const { page } = nftPagination
        setLoader(true)
        setLoadMore(true)
        // get more NFTs for explore section
        getNFTs(page + 1, 40, nftFilter)
    }

    const getNFTs = (page = 1, limit = 40, filter = null) => {
        let nftQS = { page, limit, explore: true }

        if (filter)
            nftQS = { ...nftQS, ...filter }

        if (props.blockChain)
            nftQS.blockChain = props.blockChain

        if (props.isFavourite)
            nftQS.isFavourite = props.isFavourite
        if (props.draft)
            nftQS.draft = props.draft

        if (props.onSale)
            nftQS.onSale = props.onSale

        if (props.collectionId)
            nftQS.collectionId = props.collectionId

        if (props.authorId)
            nftQS.authorId = props.authorId

        if (props.creatorId)
            nftQS.creatorId = props.creatorId

        if (props.collectorId)
            nftQS.collectorId = props.collectorId

        if (props.ownerId)
            nftQS.ownerId = props.ownerId

        if (props.collectorId)
            nftQS.collectorId = props.collectorId

        if (props.categoryId)
            nftQS.categoryId = props.categoryId

        const qs = ENV.objectToQueryString(nftQS)
        props.getNFTs(qs)
    }

    // apply search & filters
    useEffect(() => {
        if (props.filter)
            applyFilters(props.filter)
    }, [props.filter]) // eslint-disable-line react-hooks/exhaustive-deps

    const applyFilters = (filter) => {
        let filterData = [];
        if (filter) {
            if (filter.status) {
                let title = '';
                if (filter.status === 1)
                    title = "On Auction";
                else if (filter.status === 2)
                    title = "Has Offer";
                else if (filter.status === 3)
                    title = "New";

                filterData.push({ title, type: 1 })
            }
            if (filter.mintType) {
                let title = '';
                if (filter.mintType === 1)
                    title = "Single Mint";
                else if (filter.mintType === 2)
                    title = "Multiple Mint";

                filterData.push({ title, type: 1 })
            }
            if (filter.minPrice || filter.maxPrice) {
                let title = '';
                if (filter.minPrice && filter.maxPrice) {
                    title = `Min ${filter.minPrice} ${filter.currency} & Max ${filter.maxPrice} ${filter.currency}`
                }
                else if (filter.minPrice) {
                    title = `Min ${filter.minPrice} ${filter.currency}`
                }
                else if (filter.maxPrice) {
                    title = `Max ${filter.maxPrice} ${filter.currency}`
                }
                filterData.push({ title, type: 2 })
            }
            if (filter.categoryId) {
                let title = filter.categoryName;
                filterData.push({ title, type: 3 })
            }
            if (filter.creatorId) {
                let title = filter.creatorName;
                filterData.push({ title, type: 3 })
            }
            if (filter.collectionId) {
                let title = filter.collectionName;
                filterData.push({ title, type: 3 })
            }
        }

        setApplyFilterData(filterData);

        if (filter) {
            setLoader(true)
            setNftFitler(filter)
            getNFTs(1, 40, filter)
        }
    }
    const resetAllFilters = (value) => {
        setResetFilters(value);
        if (value) {
            applyFilters()
        }
    }

    const reloadPage = () => {
        setLoader(true)
        getNFTs(1, 40, nftFilter)
    }

    const handleFavouriteClick = (itemId) => {
        const data = {
            nftId: itemId
        }
        props.favoriteToggle(data)
    }

    useEffect(() => {
        if (props.nft.favoriteNftAuth) {
            updateNfFavorite(props.nft.favoriteNft?._id, props.nft.isFavourite, props.nft.likesCount)
        }

    }, [props.nft.favoriteNftAuth])

    const updateNfFavorite = async (id, isFavorite, likesCount) => {
        const seletedNFT = await nfts.find(item => (item._id === id))
        seletedNFT.isLike = isFavorite;
        seletedNFT.totalLikes = likesCount
        props.beforeNFT()
    }



    return (
        <section className="explore-area pt-0">
            {loader && <FullPageLoader />}
            <div className="container-fluid p-0">

                <Row className='explore-filters'>
                    <Col md={12}>
                        <SearchFilters
                            {...(!!props.draft && { draft: props.draft })}
                            setShowFilter={setShowFilter} showFilter={showFilter} filters={true} applyFilterData={applyFilterData} resetAllFilters={resetAllFilters} />
                    </Col>
                </Row>
                <div className={` ${Filter ? 'active' : ''} d-flex collection-wrapper`}>
                    {/* <button className="side-toggle-btn" onClick={() => setFilter(!Filter)}><i className="fas fa-sort-amount-down-alt" /></button> */}
                    {showFilter && <div className="items-sidebar explore-seidebar">
                        <FilterSearch
                            blockChain={props.blockChain}
                            applyFilters={applyFilters}
                            items={true}
                            activity={false}
                            properties={true}
                            showAuthorFilters={true}
                            showCatFilters={true}
                            showColFilters={true}
                            resetFilters={resetFilters}
                            resetAllFilters={resetAllFilters}
                        />
                    </div>}

                    <div className={`items-nfts ${showFilter ? "" : "no-sidebar"} `}>
                        <div className="reverse-section d-flex justify-content-between align-items-center">
                            <div className="reset-div">
                                <span><FontAwesomeIcon onClick={reloadPage} icon={faArrowRotateRight} className="cursor-pointer" /></span>
                                {nftPagination?.total > 0 && <span className="ml-2">{nftPagination?.total} Items</span>}
                            </div>
                        </div>
                        <div className="nfts-collection-wrapper w-100">
                            <div className="items explore-items">
                                {nfts && nfts.length > 0 ?
                                    nfts.map((item, idx) => {
                                        return (
                                            <div key={`edth_${idx}`} className={`item explore-item`} data-groups={item.group ? item.group : '["art","sports"]'}>
                                                <div className="card">
                                                    <div className="image-over">
                                                        <Link to={`/item-details/${window.btoa(item._id)}`}>
                                                            <img className="card-img-top"
                                                                // src={ipfsToUrl(item.image)}
                                                                src={item.imageLocal}
                                                                alt="img" />
                                                        </Link>
                                                    </div>
                                                    <div className="card-caption">
                                                        <div className="card-body custom-top-collection-body py-3 ">
                                                            <div className="nft-name d-flex justify-content-between align-items-center">
                                                                <Link to={`/item-details/${window.btoa(item._id)}`} className="d-flex align-items-center">
                                                                    <h5 className="mb-0">{item.name}</h5>



                                                                </Link>
                                                                <div className="likes-section d-flex align-items-center">
                                                                    <span className='heart-icon {active}'>
                                                                        {item?.isLike ?
                                                                            <FontAwesomeIcon onClick={() => handleFavouriteClick(item._id)} icon={faHeart} style={{ "color": "red" }} /> : <FontAwesomeIcon onClick={() => handleFavouriteClick(item._id)} icon={faHeart} />
                                                                        }
                                                                    </span>
                                                                    {item?.totalLikes >= 0 && <span className='likes-count ml-1'>{item?.totalLikes}</span>}
                                                                </div>
                                                            </div>
                                                            <div className='nft-name d-flex align-items-center mb-1'>
                                                                <Link to={`/artist/${item?.owner?.username}`}>{item?.owner?.username}</Link>
                                                                {item?.owner?.adminVerified === 1 &&
                                                                    <span className='banner-checked ml-1'><img className="img-fluid" src={YellowChecked} /></span>
                                                                }

                                                            </div>
                                                            <div className="biding-section">
                                                                <div className="biding-section-in d-flex align-items-center justify-content-between ">
                                                                    {item?.currentPrice >= 0 && <div className="biding-data">
                                                                        <span className="purple-span">Price</span>
                                                                        <span className="price float-right">{item.currentPrice} {item?.currency}</span>
                                                                    </div>}

                                                                    {item?.topBid >= 0 ?
                                                                        (<div className="biding-data ml-3">
                                                                            <span className="purple-span">Highest Bid</span>
                                                                            <span className="price float-right">{item?.topBid} {item?.topBidCurrency}</span>
                                                                            <img className="img-fluid custom-chain-icon" src={ENV.chainsConfigs[ENV.numberToChainId[item?.blockChain]]?.currencyImage} />
                                                                        </div>)
                                                                        :
                                                                        item?.lastPrice?.price >= 0 ?
                                                                            (<div className="biding-data ml-3">
                                                                                <span className="purple-span">Last Price</span>
                                                                                <span className="price">{item.lastPrice.price} {item.lastPrice.currency}</span>
                                                                                <img className="img-fluid custom-chain-icon" src={ENV.chainsConfigs[ENV.numberToChainId[item?.blockChain]]?.currencyImage} />
                                                                            </div>) :
                                                                            <img className="img-fluid custom-chain-icon" src={ENV.chainsConfigs[ENV.numberToChainId[item?.blockChain]]?.currencyImage} />}



                                                                </div>
                                                            </div>


                                                            <div className="buy-section text-center" onClick={() => navigate(`/item-details/${window.btoa(item._id)}`)}>
                                                                {item.sellingMethod ?
                                                                    (userId == item?.owner?._id
                                                                        ? <Link className="buy-btn" to={`/item-details/${window.btoa(item._id)}`}>{"View Details"}</Link>
                                                                        : <Link className="buy-btn" to={`/item-details/${window.btoa(item._id)}`}>{(item?.sellingMethod) === 1 ? "Buy Now" : (item?.sellingMethod) === 2 ? "Place A Bid" : ""}</Link>)
                                                                    :
                                                                    (userId == item?.owner?._id
                                                                        ? <Link className="buy-btn" to={`/item-details/${window.btoa(item._id)}`}>{"List to sale/bid"}</Link>
                                                                        : <Link className="buy-btn" to={`/item-details/${window.btoa(item._id)}`}>{"Not for sale"}</Link>)
                                                                }   </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                    : <div className="no-data border "><p className="text-center">No Items Found to Explore</p></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {loadMoreBtn ?
                    <div className="row">
                        <div className="col-12 text-center mb-3">
                            <a id="load-btn" className="btn btn-danger offer mt-4" onClick={loadMore}>{initData.btn_1}</a>
                        </div>
                    </div>
                    : ''
                }
            </div>
        </section>
    )
}

const mapStateToProps = state => ({
    nft: state.nft
});

export default connect(mapStateToProps, { beforeNFT, getNFTs, favoriteToggle })(ExploreAll);