import {
    BEFORE_NFT,
    GET_NFTS,
    GET_NFT,
    UPSERT_NFT,
    SEARCH_NFT,
    BEFORE_METADATA,
    REFRESH_METADATA,
    BEFORE_CANCEL_LISTING,
    CANCEL_LISTING,
    BEFORE_LISTING,
    TRANSFER_NFT,
    SET_FAVOURITE,
    BEFORE_BUY_NFT,
    BUY_NFT
} from '../../redux/types';

const initialState = {
    nftsData: {},
    nftsAuth: false,
    upsertAuth: false,
    searchAuth: false,
    searchData: null,
    refreshAuth: false,
    cancelListingAuth: false,
    transferedNFT: false,
    favoriteNftAuth: false,
    favoriteNft: null,
    isFavourite: false,
    likesCount: null,

    buyAuth: false,
    buyNFTData: null,
}

export default function nftsRed(state = initialState, action) {
    switch (action.type) {
        case BEFORE_BUY_NFT:
            return {
                ...state,
                buyAuth: false,
                buyNFTData: null
            }
        case BUY_NFT:
            return {
                ...state,
                buyAuth: true,
                buyNFTData: action.payload
            }
        case BEFORE_LISTING:
            return {
                ...state,
                cancelListingAuth: false
            }
        case BEFORE_CANCEL_LISTING:
            return {
                ...state,
                cancelListingAuth: false
            }
        case CANCEL_LISTING:
            return {
                ...state,
                cancelListingAuth: true
            }
        case BEFORE_METADATA:
            return {
                ...state,
                refreshAuth: false
            }
        case REFRESH_METADATA:
            return {
                ...state,
                refreshAuth: true
            }
        case UPSERT_NFT:
            return {
                ...state,
                nftsData: action.payload,
                upsertAuth: true
            }
        case SEARCH_NFT:
            return {
                ...state,
                searchData: action.payload,
                searchAuth: true
            }
        case GET_NFT:
            return {
                ...state,
                nftsData: action.payload,
                isFavourite: action.payload.isFavourite,
                likesCount: action.payload.likesCount,
                nftsAuth: true
            }
        case GET_NFTS:
            return {
                ...state,
                nftsData: action.payload,
                nftsAuth: true
            }
        case BEFORE_NFT:
            return {
                ...state,
                nftsData: {},
                nftsAuth: false,
                upsertAuth: false,
                searchAuth: false,
                searchData: null,
                cancelListingAuth: false,
                favoriteNftAuth: false,
                favoriteNft: null,
                isFavourite: false,
                likesCount: null
            }
        case TRANSFER_NFT:
            return {
                ...state,
                transferedNFT: true,
            };
        case SET_FAVOURITE:
            return {
                ...state,
                favoriteNftAuth: true,
                favoriteNft: action.payload.data,
                isFavourite: action.payload.isFavourite,
                likesCount: action.payload.likesCount
            };
        default:
            return {
                ...state
            }
    }
}

// import { BEFORE_NFT, GET_NFTS, GET_NFT, UPSERT_NFT, SEARCH_NFT } from '../../redux/types';

// const initialState = {
//     nftsData: { nfts: [], pagination: null},
//     nftsAuth: false,
//     upsertAuth: false,
//     searchAuth: false,
//     searchData: null
// }

// export default function (state = initialState, action) {
//     switch (action.type) {
//         case UPSERT_NFT:
//             return {
//                 ...state,
//                 nftsData: action.payload,
//                 upsertAuth: true
//             }
//         case SEARCH_NFT:
//             return {
//                 ...state,
//                 searchData: action.payload,
//                 searchAuth: true
//             }
//         case GET_NFT:
//             return {
//                 ...state,
//                 nftsData: action.payload,
//                 nftsAuth: true
//             }
//         case GET_NFTS:
//             let nfts =[...state.nftsData.nfts,...action.payload.nfts]

//             return {
//                 ...state,
//                 nftsData: {
//                     nfts:[...nfts],
//                     pagination: action.payload.pagination 
//                 },
//                 nftsAuth: true


//             }
//         case BEFORE_NFT:
//             return {
//                 ...state,
//                 nftsData: {nfts: [], pagination: null},
//                 nftsAuth: false,
//                 upsertAuth: false,
//                 searchAuth: false,
//                 searchData: null
//             }
//         default:
//             return {
//                 ...state
//             }
//     }
// }