import { BEFORE_SETTINGS, GET_SETTINGS, SET_SUBSCRIBE, GET_ERRORS,THEME_SETTINGS,BEFORE_THEME_SETTINGS,GET_TOKEN } from '../../../redux/types';
import { emptyError } from '../../../redux/shared/error/error.action';
import { ENV } from '../../../config/config';
import { toast } from 'react-toastify';


export const beforeSettings = () => {
    return {
        type: BEFORE_SETTINGS
    }
}

export const getSettings = () => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}settings/get`;

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: GET_SETTINGS,
                payload: data.settings
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}

export const getTokenSettings = (qs = '') => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}settings/get-token-settings`;

    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            toast.dismiss()
            // toast.success(data.message, {
            //     toastId: 8
            // })
            dispatch({
                type: GET_TOKEN,
                payload: data.settings
            })
        } else {
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(error => {
        if (error.response && error.response.data) {
            const { data } = error.response
            if (data.message)
                toast.error(data.message)
        }
        dispatch({
            type: GET_ERRORS,
            payload: error
        })
    })
};

export const subscribe = (payload) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}settings/subscribe`;
    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
        body: JSON.stringify(payload)
    }).then(res => res.json()).then(data => {
        if (data.status) {
            toast.success(data.message)
            dispatch({
                type: SET_SUBSCRIBE,
                payload: data.data
            })
        } else {
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const beforeThemeSettings = () => {
    return {
        type: BEFORE_THEME_SETTINGS
    }
}

export const themeSettings = (value) => {
    return {
        type: THEME_SETTINGS,
        payload:value
    }
}