import { toast } from 'react-toastify';
import { ENV } from './../../config/config';
import { SET_NOTIFICATIONS, SET_UNREAD_COUNT, UPDATE_UNREAD_COUNT, UPDATE_NOTIFICATIONS, SET_VERIFY_EMAIL, BEFORE_FOLLOWING, BEFORE_FOLLOWER, SET_SEND_EMAIL, UPDATE_NOTI_COUNT, SET_NOTIFICATIONS_COUNT, SET_FOLLOWINGS, SET_FOLLOWERS, GET_ERRORS, SET_USER, GET_USER, SET_CREATORS, TOP_SELLERS, SET_INDIVIDUAL_USER, BEFORE_USER, SET_BANNER, USER_TRADE, SET_FOLLOW, REGISTER_First } from '../../redux/types';
import { emptyError } from '../../redux/shared/error/error.action';

export const beforeUser = () => {
    return {
        type: BEFORE_USER
    }
}

export const beforeFollower = () => {
    return {
        type: BEFORE_FOLLOWER
    }
}

export const beforeFollowing = () => {
    return {
        type: BEFORE_FOLLOWING
    }
}

export const updateNotiCount = () => {
    return {
        type: UPDATE_NOTI_COUNT
    }
}

export const updateUnReadCount = () => {
    return {
        type: UPDATE_UNREAD_COUNT
    }
}

export const updateNotifications = () => {
    return {
        type: UPDATE_NOTIFICATIONS
    }
}

// method to set user data
export const setUser = (user) => dispatch => {
    dispatch(emptyError())
    dispatch({
        type: SET_USER,
        payload: user
    })
}

export const setBanner = (body) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}users/set-banner/`;
    fetch(url, {
        method: 'PUT',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
        body
    }).then(res => res.json()).then(data => {
        if (data.status) {
            ENV.encryptUserData(data.data);
            dispatch({
                type: SET_BANNER,
                payload: data.data
            })
            toast.success(data.message)
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
}

// method to get user data
export const getUser = () => dispatch => {
    dispatch({
        type: GET_USER
    })
}

// method to login using wallet address and sign
export const login = (payload) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}auth/login/`;
    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(payload)
    }).then(res => res.json()).then(data => {
        if (data.status) {
            ENV.encryptUserData(data.data);
            dispatch({
                type: SET_USER,
                payload: data.data
            })
        } else if (data.registerFirst) {
            dispatch({ type: REGISTER_First })
        }
        else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

// method to login using wallet address and sign
export const followToggle = (payload) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}users/follow-toggle/`;
    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
        body: JSON.stringify(payload)
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: SET_FOLLOW,
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

// method to signup using wallet address, sign, and payload
export const signup = (payload) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}auth/register/`;
    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token
        },
        body: JSON.stringify(payload)
    }).then(res => res.json()).then(data => {
        if (data.status) {
            ENV.encryptUserData(data.data);
            dispatch({
                type: SET_USER,
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
            toast.error(data.message)

        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

// method to update user's profile, update user's payload
export const updateProfile = (body) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}users/`;
    fetch(url, {
        method: 'PUT',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
        body
    }).then(res => res.json()).then(data => {
        if (data.status) {
            ENV.encryptUserData(data.data);
            dispatch({
                type: SET_USER,
                payload: data.data
            })
            toast.success(data.message)
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

// method to get authors' details
export const getTopSellers = (qs = '') => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}users/top-sellers`

    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: TOP_SELLERS,
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

// method to get authors' details
export const getCreators = (qs = '') => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}users/creators`

    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: SET_CREATORS,
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

// method to get authors' details
export const getUserById = (userId = '', fromAuthor=0) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}users/${userId}?fromAuthor=${fromAuthor}`;
    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: SET_INDIVIDUAL_USER,
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

// method to get authors' followers
export const getFollowers = (userId, qs = '') => dispatch => {
    dispatch(emptyError());

    let url = `${ENV.url}users/follower/${userId}`

    if (qs)
        url += `?${qs}`

    // const url = `${ENV.url}users/follower/${userId}`;
    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: SET_FOLLOWERS,
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

// method to get authors' followings
export const getFollowings = (userId, qs = '') => dispatch => {
    dispatch(emptyError());

    let url = `${ENV.url}users/following/${userId}`

    if (qs)
        url += `?${qs}`

    // const url = `${ENV.url}users/following/${userId}`;
    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: SET_FOLLOWINGS,
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

// method to get authors' notifications
export const getNotifications = (qs = '') => dispatch => {
    dispatch(emptyError());

    let url = `${ENV.url}notification/list`

    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: SET_NOTIFICATIONS,
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

// method to get authors' notifications
export const getNotificationsCount = (qs = '') => dispatch => {
    dispatch(emptyError());

    let url = `${ENV.url}notification/noti-count`

    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: SET_NOTIFICATIONS_COUNT,
                payload: data.data.notificationsCount
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

// method to get authors' notifications
export const getUnReadCount = (qs = '') => dispatch => {
    dispatch(emptyError());

    let url = `${ENV.url}chat/un-read-count`

    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: SET_UNREAD_COUNT,
                payload: data.data.unReadMessageCount
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const listTrade = (qs) => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}users/list-trade`;
    if (qs)
        url += `?${qs}`
    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then(res => res.json()).then(data => {
        if (data.success) {
            dispatch({
                type: USER_TRADE,
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

// method to get authors' details
export const setIndividualUserData = (user) => dispatch => {
    dispatch(emptyError());
    dispatch({
        type: SET_INDIVIDUAL_USER,
        payload: user
    })
};

// method to send email
export const sendEmail = (payload) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}users/send-email`;
    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
        body: JSON.stringify(payload)
    }).then(res => res.json()).then(data => {
        if (data.status) {
            ENV.encryptUserData(data.data);
            toast.success(data.message)
            dispatch({
                type: SET_SEND_EMAIL,
                payload: true
            })
        } else {
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

export const verifyEmail = (payload) => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}users/verify-email`;
    fetch(url, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        },
        body: JSON.stringify(payload)
    }).then(res => res.json()).then(data => {
        if (data.status) {
            ENV.encryptUserData(data.data);
            toast.success(data.message)
            dispatch({
                type: SET_VERIFY_EMAIL,
                payload: true
            })
        } else {
            toast.error(data.message)
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};