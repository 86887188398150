import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
 import Select from 'react-select';
import {  useLocation } from 'react-router-dom';
 import Explore from '../explore/explore-all';
import { ENV } from '../../config/config';
import { useWeb3React } from '@web3-react/core';

const initData = {
    pre_heading: "Explore",
    heading: "Explore NFTs",
    // content: "Explore the NFTs by professional filter options and top trending.",
    btn_1: "Load More"
}

const ExploreMarketplace = (props) => {
    const [categories, setCategories] = useState(null);
    const [selectedOption, setSelectedOption] = useState(ENV.ChainOptions[0]);
    const [categoryId, setCategoryId] = useState("");
    const [intro, setIntro] = useState(false);
    const { pathname, state } = useLocation();
  	const { chainId } = useWeb3React();

    useEffect(() => {
        if (state){
            setSelectedOption(state)
        }
    }, [state])

    useEffect(() => {
 
        window.scroll(0, 0)
        // set intro section
        if (pathname === '/explore-all') {
            setIntro(true)
        }

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (chainId && !state) {
            const option = ENV.ChainOptions?.find((elem) => elem.chainIds?.includes(Number(chainId)))
            if (option) {
                localStorage.setItem('selectedChain', JSON.stringify(option))
                setSelectedOption(option)
            }
        }
    }, [chainId])

    useEffect(() => {
        if (props.category.getAuth) {
            const { categories } = props.category
            setCategories(categories)
        }
    }, [props.category.getAuth])

    useEffect(() => {
        let options = JSON.parse(localStorage.getItem('selectedChain'));
        if (options && !state)
            setSelectedOption(options)
    }, [])

    const changeSetSelectedOption = (options) => {
        localStorage.setItem('selectedChain', JSON.stringify(options))
        setSelectedOption(options)
    }

    return (
        <section className="explore-area explore-nfts-page">
            <div className="container-fluid p-0">
                {
                    intro &&
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-7">
                            <div className="intro text-center">
                                <h3 className="mt-3 mb-0">{initData.heading}</h3>
                                <div className="blockchain-section d-flex justify-content-center align-items-center">
                                    <span className="bockchain-tag">Blockchain</span>
                                    <span className="blockchain-select">
                                        {
                                            selectedOption &&
                                            <Select
                                                value={selectedOption}
                                                onChange={changeSetSelectedOption}
                                                options={ENV.ChainOptions}
                                                isSearchable={false}
                                                classNamePrefix="elumnt-select"
                                            />
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="row justify-content-center text-center">
                    <div className="col-12">
                        <div className="explore-menu btn-group-toggle d-flex justify-content-start flex-wrap" data-toggle="buttons">
                            <label className={`btn text-uppercase p-2 d-flex align-items-center ${categoryId === "" ? "active" : ""}`} onClick={() => setCategoryId('')}>
                                <input type="radio" defaultValue={1} defaultChecked className="explore-btn" />
                                <span>All</span>
                            </label>
                            {
                                categories && categories.length ? categories.map((category, key) => {
                                    return (
                                        <label key={key} className={`btn text-uppercase p-2 d-flex align-items-center ${category._id === categoryId ? "active" : ""}`} onClick={() => setCategoryId(category._id)}>
                                            <input type="radio" defaultValue={1} defaultChecked className="explore-btn" />
                                            <span>{category.name}</span>
                                        </label>
                                    )
                                }) : ''
                            }
                        </div>
                    </div>
                </div>
                <Explore
                    blockChain={selectedOption?.value}  // selected BlockChain 
                    categoryId={categoryId} // selected category id
                />
            </div>
        </section>
    )
}

const mapStateToProps = state => ({
    category: state.category
});

export default connect(mapStateToProps, {})(ExploreMarketplace)