import React, { useState, useEffect } from "react";
import { Tab, Tabs, Modal, Form } from "react-bootstrap";
import Web3 from "web3";
import { Redirect, useHistory, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { beforeHome, getLoginPageNft } from "../homepage/home.action";
import { signRequest } from "../../utils/web3";
import { beforeWallet, setWalletAddress } from "../wallet/wallet.action";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { beforeUser, login, signup } from "../user/user.action";
import validator from "validator";
import connectors from "./connector";
import { toast } from "react-toastify";
import { ENV } from "./../../config/config";
import loginImage from "../../assets/images/login-cat-image.png";
import coinBaseImage from "../../assets/images/coinbase.jpg";
import walletConnectImage from "../../assets/images/walletconnect.png";
import logo from "../../assets/images/mm.png";
import { Link } from "react-router-dom";
import FullPageLoader from "../full-page-loader/full-page-loader";

const { requiredChainName, chainsConfigs, userDefaultImg } = ENV;
const CHAIN_NAME = requiredChainName;

const Wallet = (props) => {
  const { account, activate, deactivate, library } = useWeb3React();
  const [connectedAddress, setConnectedAddress] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [valuesValidation, setValuesValidation] = useState(false);
  const [submitCheck, setSubmitCheck] = useState(false);
  const [signUpCheck, setSignUpCheck] = useState(false);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [msg, setMsg] = useState({
    email: "",
    username: "",
    status: "",
    fullName: "",
  });

  const [nft, setNft] = useState(undefined);

  useEffect(() => {
    setLoader(true);
    props.getLoginPageNft();
  }, []);

  useEffect(() => {
    if (props.home.loginNftAuth) {
      setNft(props.home.loginNft);
      setLoader(false);
      props.beforeHome();
    }
  }, [props.home.loginNftAuth]);

  useEffect(() => {
    if (props.error) {
      setLoader(false);
    }
  }, [props.error]);

  //Check showRegister or NOt
  useEffect(() => {
    if (props.user.registerFirst) {
      setShow(true);
      props.beforeUser();
    }
  }, [props.user.registerFirst]);
  useEffect(() => {
    if (localStorage.getItem("encuse")) {
      navigate(`${signUpCheck ? "/profile" : "/explore-all"}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (account) {
      localStorage.setItem("connectedAddress", account);
    }
    if (account && !localStorage.getItem("encuse")) {
      login(account);
    }
    setConnectedAddress(account);
    setWalletAddress(account);
  }, [account]);

  useEffect(() => {
    if (library) {
      window.library = library;
    }
  }, [library]);

  useEffect(() => {
    if (props.error && props.error.notUnique) {
      setErrorMessage(props.error.message);
    }
  }, [props.error]);

  function _isvalidUserName(str) {
    var re = /^[a-z][a-z0-9]+(?:-[a-z0-9]+)?$/i;
    return str.length > 5 && str.length < 21 && re.test(str);
  }
  const signup = async (e) => {
    e.preventDefault();
    setLoader(true);
    setSubmitCheck(true);

    if (
      !validator.isEmpty(username) &&
      _isvalidUserName(username) &&
      email &&
      fullName &&
      valuesValidation
    ) {
      let sign = await signRequest();
      let payload = {
        address: connectedAddress,
        signature: sign,
        username: username,
        email: email,
        fullName: fullName,
      };
      setSignUpCheck(true);
      props.signup(payload);
    } else {
      let data = {
        username: "",
        status: "",
        email: "",
      };
      if (validator.isEmpty(username)) {
        data.username = "Username Is Required.";
      }
      if (!_isvalidUserName(username)) {
        data.username = "Please provide valid username";
      }

      if (validator.isEmpty(fullName)) {
        data.fullName = "FullName Is Required.";
      }

      if (validator.isEmpty(email)) {
        data.email = "Email Is Required.";
      }
      if (!valuesValidation) {
        data.status = "Agree To Privacy Policy Is Required.";
      }

      setMsg(data);
    }
    setLoader(false);
  };

  const login = async (addresss) => {
    let sign = await signRequest();
    setLoader(true);
    let payload = {
      address: addresss,
      password: sign,
    };
    props.login(payload);
    setLoader(false);
  };

  const walletActivation = (walletInjector, name, _chainId) => {
    const connector = walletInjector.provider;
    if (walletInjector.name === "MetaMask" && !window.ethereum) {
      alert("Please install Metamask first!");
      return false;
    }
    if (
      connector instanceof WalletConnectConnector &&
      connector.walletConnectProvider?.wc?.uri
    ) {
      connector.walletConnectProvider = undefined;
    }

    connector &&
      activate(connector, undefined, true)
        .then(async (res) => {
          const web3Provider = await connector.getProvider();
          web3Provider.enable();
          const web3 = new Web3(web3Provider);
          window.walletPO = web3;
          localStorage.setItem("connectedAddress", connectedAddress);
          localStorage.setItem("chainId", _chainId);
          localStorage.setItem("provider", name);
          const networkDetails = {
            chainId: `0x${Number(_chainId).toString(16)}`,
            chainName: CHAIN_NAME[_chainId],
            nativeCurrency: chainsConfigs[_chainId].nativeCurrency,
            rpcUrls: [chainsConfigs[_chainId].rpcUrl],
            blockExplorerUrls: [chainsConfigs[_chainId].explorer],
          };

          // await web3.currentProvider.request({
          //     method: 'wallet_switchEthereumChain',
          //     params: [{
          //         chainId: web3.utils.toHex(_chainId)
          //     }]
          // })

          if (window?.ethereum?.networkVersion !== _chainId) {
            await window?.ethereum?.request({
              method: "wallet_switchEthereumChain",
              params: [
                {
                  chainId: web3.utils.toHex(_chainId),
                },
              ],
            });
          }
          // await window?.ethereum?.request({
          //     method: 'wallet_addEthereumChain',
          //     params: [networkDetails],
          // });
        })
        .catch(async (error) => {
          console.log("error", error);
          console.log(
            "error instanceof UnsupportedChainIdErrorerror instanceof UnsupportedChainIdError"
          );
          console.log(error instanceof UnsupportedChainIdError);
          if (error instanceof UnsupportedChainIdError || error.code === 4902) {
            const networkDetails = {
              chainId: `0x${Number(_chainId).toString(16)}`,
              chainName: CHAIN_NAME[_chainId],
              nativeCurrency: chainsConfigs[_chainId].nativeCurrency,
              rpcUrls: [chainsConfigs[_chainId].rpcUrl],
              blockExplorerUrls: [chainsConfigs[_chainId].explorer],
            };

            await window?.ethereum?.request({
              method: "wallet_addEthereumChain",
              params: [networkDetails],
            });
            await window?.ethereum?.request({
              method: "wallet_switchEthereumChain",
              params: [
                {
                  chainId: `0x${Number(_chainId).toString(16)}`,
                },
              ],
            });
            activate(connector);
          } else {
            // setPendingError(error?.message || 'Something went wrong');
          }

          // let chains = '', chainsLength = Object.keys(ENV.requiredChainName)?.length
          // Object.entries(ENV.requiredChainName).forEach(([key, value], index) => {
          //     if (index) {
          //         if (index === chainsLength - 1)
          //             chains += ' or '
          //         else if (index + 1 < chainsLength)
          //             chains += ', '
          //     }

          //     chains += value
          // })

          // toast.error(`Please switch to ${chains} in order to use all features of Marketplace`)
          // activate(connector);
        });
  };

  const disconnect = () => {
    deactivate();
  };

  if (props.user.userAuth) {
    return navigate(`${signUpCheck ? "/profile" : "/explore-all"}`); // <Redirect to={`${signUpCheck ? '/profile' : '/explore-all'}`} />
  }
  let chainIds = Object.keys(chainsConfigs);
  return (
    <>
      {" "}
      {loader && <FullPageLoader />}
      <div
        className={`wallet-connect-area login-page`}
        style={{ height: "100vh" }}
      >
        {localStorage.getItem("encuse") ? (
          ""
        ) : (
          <div className="container-fluid m-0 p-0 h-100">
            {
              <>
                <div className="d-flex  h-100">
                  <div className="login-nft-image h-100">
                    <div className="login-section h-100 ">
                      <div className="login-image h-100">
                        <div className="login-overlay">
                          <span>
                            {nft?.name} {nft?.owner?.username ? "by " : ""}
                            <span className="sandikasiltawa">
                              {nft?.owner?.username}
                            </span>
                          </span>
                        </div>
                        <img
                          src={nft?.image ? nft?.image : userDefaultImg}
                          className="img-fluid"
                          alt="image" />
                      </div>
                    </div>
                  </div>
                  <div className="login-nft-categories">
                    <div className="login-area ">
                      <div className="login-logo">
                        <Link className="navbar-brand text-light" to="/">
                          <span className="d-block logo">
                            <img src={logo} className="img-fluid" alt="logo" />
                          </span>
                        </Link>
                      </div>
                      <div className="login-content">
                        <h3>Connect your wallet</h3>
                        <p>
                          To log in or register your Mutoplace account, you
                          must connect your wallet. Please choose one of the
                          several wallet providers we have below:{" "}
                        </p>
                        <p>
                          To learn more, click here:{" "}
                          <Link to={"/how-it-works"}>
                            How does a decentralized wallet work?
                          </Link>{" "}
                        </p>
                        <Modal
                          show={show}
                          onHide={() => setShow(false)}
                          dialogClassName="modal-10w custom-login-modal"
                          aria-labelledby="contained-modal-title-vcenter"
                          centered
                        >
                          <Modal.Body>
                            <h2>Your journey begins now...</h2>
                            <p>
                              TIme to choose a display name and enter your email
                              address to receive updates about Mutoplace, when
                              your NFTs sell or receive offers from your items
                              or collections.
                            </p>
                            <Form onSubmit={(e) => signup(e)}>
                              <Form.Group
                                className="mb-4"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  name="username"
                                  type="text"
                                  onChange={(e) => {
                                    setUsername(e.target.value);
                                    if (submitCheck) {
                                      if (e.target.value) {
                                        setMsg({ ...msg, username: "" });
                                      } else {
                                        setMsg({
                                          ...msg,
                                          username: "Username Is Required.",
                                        });
                                      }
                                    }
                                  }}
                                  placeholder="User name"
                                />
                              </Form.Group>
                              {msg.username && (
                                <p
                                  style={{ color: "red" }}
                                  className="text-red"
                                >
                                  {msg.username}
                                </p>
                              )}
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  name="fullName"
                                  type="fullName"
                                  onChange={(e) => {
                                    setFullName(e.target.value);
                                    if (submitCheck) {
                                      if (e.target.value) {
                                        setMsg({ ...msg, fullName: "" });
                                      } else {
                                        setMsg({
                                          ...msg,
                                          fullName: "Name Required.",
                                        });
                                      }
                                    }
                                  }}
                                  placeholder="Full Name"
                                />
                              </Form.Group>
                              {msg.fullName && (
                                <p
                                  style={{ color: "red" }}
                                  className="text-red"
                                >
                                  {msg.fullName}
                                </p>
                              )}
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                              >
                                <Form.Control
                                  name="email"
                                  type="email"
                                  onChange={(e) => {
                                    setEmail(e.target.value);
                                    if (submitCheck) {
                                      if (e.target.value) {
                                        setMsg({ ...msg, email: "" });
                                      } else {
                                        setMsg({
                                          ...msg,
                                          email: "Email Is Required.",
                                        });
                                      }
                                    }
                                  }}
                                  placeholder="Email Address"
                                />
                              </Form.Group>
                              {msg.email && (
                                <p
                                  style={{ color: "red" }}
                                  className="text-red"
                                >
                                  {msg.email}
                                </p>
                              )}

                              {errorMessage && (
                                <p
                                  style={{ color: "red" }}
                                  className="text-red"
                                >
                                  {errorMessage}
                                </p>
                              )}

                              <label className="container mb-5 d-block">
                                I have read and accept the{" "}<a href="/terms-conditions">Terms of Service</a> and confirm that I am at least 13 years old
                                <input type="checkbox" />
                                <span className="checkmark"></span>
                              </label>
                              <div className="d-flex align-items-center justify-content-center">
                                <label className="container mb-3 pt-2">
                                  I agree to{" "}<a href="/privacy-and-terms">Privacy Policy</a>
                                  <input
                                    type="checkbox"
                                    name="inlineRadioOptions"
                                    id="inlineRadio1"
                                    defaultValue="policy-checkbox"
                                    onChange={(e) => {
                                      setValuesValidation(e.target.checked);
                                      if (submitCheck) {
                                        if (e.target.checked) {
                                          setMsg({ ...msg, status: "" });
                                        } else {
                                          setMsg({
                                            ...msg,
                                            status:
                                              "Agree To Privacy Policy Is Required.",
                                          });
                                        }
                                      }
                                    }}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>

                              <label
                                className={`text-danger pl-1 mb-2 ${msg.status ? `` : `d-none`
                                  }`}
                              >
                                {msg.status}
                              </label>
                              <div className="login-modal-btns">
                                <button
                                  type="submit"
                                  className="btn btn-outline-warning finish-btn"
                                >
                                  Finish sign-up
                                </button>
                              </div>
                            </Form>
                            <div className="login-modal-btns">
                              <button
                                type="text"
                                onClick={() => {
                                  setShow(false);
                                  disconnect();
                                  setMsg({
                                    username: "",
                                    status: "",
                                    email: "",
                                  });
                                  let theme = localStorage.getItem("theme");
                                  localStorage.clear();
                                  localStorage.setItem("theme", theme);
                                }}
                                className="btn btn-outline-warning disconect-btn"
                              >
                                Disconnect wallet
                              </button>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>
                      <div className="login-tabs">
                        <Tabs
                          defaultActiveKey="Ethereum"
                          id="uncontrolled-tab-example"
                          className=""
                        >
                          {chainIds.map((chainId, index) => {
                            return (
                              <Tab
                                key={index}
                                eventKey={chainsConfigs[chainId].eventKey}
                                title={chainsConfigs[chainId].title}
                              >
                                <div
                                  className="item"
                                  onClick={() => {
                                    walletActivation(
                                      connectors.injected,
                                      "injected",
                                      chainId
                                    );
                                    if (
                                      window.ethereum.providers !== undefined
                                    ) {
                                      window.ethereum.setSelectedProvider(
                                        window.ethereum.providers.find(
                                          ({ isMetaMask }) => isMetaMask
                                        )
                                      );
                                      localStorage.setItem(
                                        "connectedWalletName",
                                        "MetaMask"
                                      );
                                    }
                                    walletActivation(
                                      connectors.injected,
                                      "injected",
                                      chainId
                                    );
                                  }}
                                >
                                  {/* {
                                                                        errorMessage && <p className="text-white">{errorMessage}</p>
                                                                    } */}
                                  {/* Single Wallet */}
                                  <div className="single-wallet whole-hover">
                                    <div
                                      className="d-flex align-items-center"
                                    // onClick={login}
                                    >
                                      <img
                                        className="avatar-lg d-inline-block align-top"
                                        src="/img/metamask2.png"
                                        alt=""
                                      />
                                      <span
                                        className="mb-0"
                                        style={{ cursor: "pointer" }}
                                      >
                                        MetaMask
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="item"
                                  onClick={() => {
                                    walletActivation(
                                      connectors.coinbase,
                                      "coinbase",
                                      chainId
                                    );
                                  }}
                                >
                                  {/* {
                                                                        errorMessage && <p className="text-white">{errorMessage}</p>
                                                                    } */}
                                  {/* Single Wallet */}
                                  <div className="single-wallet whole-hover">
                                    <div
                                      className="d-flex align-items-center"
                                    // onClick={login}
                                    >
                                      <img
                                        className="avatar-lg d-inline-block align-top"
                                        src={coinBaseImage}
                                        alt=""
                                      />
                                      <span
                                        className="mb-0"
                                        style={{ cursor: "pointer" }}
                                      >
                                        CoinBase
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="item"
                                  onClick={() => {
                                    walletActivation(
                                      connectors.walletConnect,
                                      "walletConnect",
                                      chainId
                                    );
                                  }}
                                >
                                  {/* {
                                                                        errorMessage && <p className="text-white">{errorMessage}</p>
                                                                    } */}
                                  {/* Single Wallet */}
                                  <div className="single-wallet whole-hover">
                                    <div
                                      className="d-flex align-items-center"
                                    // onClick={login}
                                    >
                                      <img
                                        className="avatar-lg d-inline-block align-top"
                                        src={walletConnectImage}
                                        alt=""
                                      />
                                      <span
                                        className="mb-0"
                                        style={{ cursor: "pointer" }}
                                      >
                                        WalletConnect
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Tab>
                            );
                          })}
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  wallet: state.wallet,
  user: state.user,
  home: state.home,
  error: state.error,
});

export default connect(mapStateToProps, {
  beforeWallet,
  setWalletAddress,
  login,
  signup,
  beforeUser,
  beforeHome,
  getLoginPageNft,
})(Wallet);
