import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CustomHeading from "../custom-heading/CustomHeading";
import PopularCards from "../popular-cards/PopularCards";
import "../popular-categories/popular-category.css";
function PopularCategory() {
  return (
    <>
      <section className="popular-category">
        <Container fluid className="custom-container">
          {/* <Row> */}
            <CustomHeading heading="Popular" collection=" categories" />
            <PopularCards/>
          {/* </Row> */}
        </Container>
      </section>
    </>
  );
}

export default PopularCategory;
