import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { connect } from 'react-redux';
import { beforeUser, setBanner } from './../user/user.action';
import FullPageLoader from "../full-page-loader/full-page-loader";
import { ENV } from './../../config/config';
const itemPlaceholderImg = ENV.globalPlaceholderImage


function Breadcrumb(props) {
    const params = useParams();
    const [loader, setLoader] = useState(false)
    const [ownProfile, setOwnProfile] = useState(false);
    const [image, setImage] = useState('')
    const [imageUrl, setImageUrl] = useState(null)
    const path = window.location.pathname.split('/')[1]
    const location = useLocation();

    useEffect(() => {
        const path = window.location.pathname.split('/')[1]
        const { _id, bannerImage, username } = ENV.getUserKeys();
        if (params && params.authorId && params.authorId === username) {
            setImageUrl(bannerImage);
            setOwnProfile(true);
        }
        if (path !== "category" && path !== "artist") {
            setImageUrl(null);
        }
    }, [])

    useEffect(() => {
        const path = window.location.pathname.split('/')[1]
        const { _id, bannerImage, username } = ENV.getUserKeys();
        if (params && params.authorId && params.authorId === username) {
            setImageUrl(bannerImage);
            setOwnProfile(true);
        }
         if (path !== "category" && path !== "artist") {
            setImageUrl(null);
        }
    }, [location])

    //set Collection & Category banner
    useEffect(() => {
        const path = window.location.pathname.split('/')[1]
        if (path === "category") {
            setImageUrl(props.banner)
        } else if (path === "collection") {
            setImageUrl(props.banner)
        } else if (path !== "artist") {
            setImageUrl(null);
        }
    }, [props.banner])

    useEffect(() => {
        if (props.user.bannerAuth) {
            setLoader(false)
            props.beforeUser()
        }
    }, [props.user.bannerAuth])


    const onFileChange = (e) => {
        let file = e.target.files[0];
        let fileURL = '';
        if (file) {
            if (file.type.includes('image')) {
                setLoader(true)
                fileURL = URL.createObjectURL(file)
            } else {
                file = {};
                fileURL = '';
            }
            setImageUrl(fileURL);
            setImage(file);
        }
    }

    useEffect(() => {
        if (image) {
            try {
                var formData = new FormData();
                formData.append('bannerImage', image)
                props.setBanner(formData);
            } catch (e) {
            }
        }
    }, [image])


    return (
        <>
            {
                loader ? <FullPageLoader /> :
                <>
                    {path !== 'contact' &&
                        <div>
                            <section className="breadcrumb-area d-flex align-items-center" >
                                <img src={imageUrl ? imageUrl : itemPlaceholderImg} className="img-fluid" />
                            </section>

                            <div className='custom-container'>
                                <div className='row '>
                                    <div className='col-md-12'>
                                        <div className='edit-cover-btn float-right'>
                                            {ownProfile &&
                                                <>
                                                    <div className='d-flex align-items-center justify-content-end'>
                                                        <input type="file" className="custom-file-input" id="image" accept=".png,.jpeg,.jpg,.gif,image/gif" onChange={(e) => onFileChange(e)} name="image" />
                                                        <label id="nft-imasge-label" className="custom-file-label login-btn" htmlFor="image">
                                                            {image && image.name ? "Edit Cover" : "Edit Cover"}
                                                        </label>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div className='clearfix'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </>
            }
        </>
    );
}

const mapStateToProps = state => ({
    user: state.user,
    error: state.error
});

export default connect(mapStateToProps, { setBanner, beforeUser })(Breadcrumb);