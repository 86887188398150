import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import '../custom-heading-one/custom-heading-one.css'
function CustomHeadingOne(props) {
  return (
    <>
      <section className="custom-heading-one">
        <Container fluid className="custom-container">
          <Row>
            <Col md={12} className="text-center heading-block position-relative">
              <h2>{props.heading}</h2>
              <p className='how-to-create'>{props.children}</p>
            </Col>
          </Row>
        </Container>
      </section>

    </>
  )
}

export default CustomHeadingOne