import React, { useEffect, useState } from 'react';
import { ENV } from '../../config/config'
import { ipfsToUrl } from '../../utils/functions';
const itemPlaceholderImg = ENV.globalPlaceholderImage

const NFTPreview = (props) => {
    const [imgSrc, setImgSrc] = useState('');

    useEffect(() => {
        if (props.image)
            setImgSrc(props.image);
    }, [props.image])


    return (
        <div className="create-upload-picture d-flex align-items-center justify-content-start  ">
            {imgSrc ? <div className="image-over">
                <img id="nft-image" className="card-img-top" src={(imgSrc)} />
                {!props.hideCloseBtn && <div className='overlay'>
                    <button type="button"
                        className="close-button"
                        onClick={() => {
                            setImgSrc(null)
                            props.unsetNFTImage()
                        }}
                        aria-label="Close">X</button>
                </div>}
            </div>

                : <div className='content'>
                    <p className='mb-4'>JPEG, PNG or GIF Max 100mb.</p>
                    <div className="file-input">
                        <input type="file" className="custom-file-input" id="image" accept=".png,.jpeg,.jpg,.gif,image/gif" onChange={(e) => props.onFileChange(e, props.name)} name="image" />
                        <label className="file-input__label" id="nft-image-label" htmlFor="image">
                            <span>Choose File</span>
                        </label>
                    </div>
                </div>
            }
        </div>

    );
}

export default NFTPreview;