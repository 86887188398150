import React, { Component } from 'react'
import { connect } from 'react-redux';
import { beforeCollection, upsertCollection, getCollection, deleteCollection } from '../collections/collections.actions';
import { beforeCategory, getCategories } from '../categories/categories.action';
import { emptyError } from '../../redux/shared/error/error.action';
import $ from 'jquery'
import SimpleReactValidator from 'simple-react-validator'
import FullPageLoader from '../full-page-loader/full-page-loader';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Link } from 'react-router-dom';
import { ENV } from '../../config/config';
import {
    getChainId,
} from './../../utils/web3'
const { collectionFeaturedImg, chainsConfigs } = ENV;
const { integerNumberValidator } = ENV
class EditCollection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSubmitted: false,
            formValid: true,
            loader: true,
            errors: '',
            collection: {
                _id: '',
                userId: '',
                categoryId: '',
                logo: '',
                featuredImg: '',
                banner: '',
                name: '',
                url: '',
                description: '',
                siteLink: '',
                discordLink: '',
                instaLink: '',
                mediumLink: '',
                telegramLink: '',
                royalty: '',
                payoutAddr: '',
                blockChain: ''
            },
            submitCheck: false,
            siteUrlCheck: true,
            discordLinkCheck: true,
            telLinkCheck: true,
            urlMsg: '',
            categories: null
        }
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            messages: {
                required: 'This field is required.'  // will override all messages
            },
        })
    }

    componentDidMount() {
        if (localStorage.getItem('encuse')) {
            let collectionId = window.location.pathname.split('/')[3];
            window.scroll(0, 0)
            this.props.getCategories()
            this.props.getCollection(collectionId, 'edit')
        }
        else {
            this.props.navigate('/login')
        }
    }

    deleteCollection = (collectionId) => {
        confirmAlert({
            title: 'Delete Collection',
            message: 'Are you sure you want to delete this collection and hide all of its items? This can only be done if you own all items in the collection.',
            buttons: [
                {
                    className: "never-mind",
                    label: 'Never mind'
                },
                {
                    className: "delete-button",
                    label: 'Yes, Delete',
                    onClick: () => {
                        this.setState({ loader: true }, () => {
                            this.props.deleteCollection(collectionId)
                        })
                    }
                }
            ]
        });
    }

    componentDidUpdate(prevProps, prevState) {
        // when collection data is deleted
        if (this.props.collection.deleteAuth) {
            const { collectionId } = this.props.collection.collection;
            if (collectionId) {
                this.props.beforeCollection()
                this.props.navigate("/my-collections")

            }
            this.setState({ loader: false })
        }

        if (this.props.error) {
            this.setState({ loader: false }, () => {
                if (this.props.error?.invalidCol)
                    this.props.navigate("/my-collections")

                this.props.emptyError()
            })
        }

        if (this.props.collection.upsertAuth) {
            this.props.beforeCollection()
            this.props.navigate('/my-collections')
        }

        if (this.props.category.getAuth) {
            const { categories } = this.props.category
            this.setState({ categories, loader: false }, () => {
                this.props.beforeCategory()
            })
        }

        if (
            this.state.collection.name === '' &&
            this.state.collection.url === '' &&
            this.state.collection.description === '' //== &&
            // this.state.weight !== prevState.weight &&
            // this.state.gender !== prevState.gender
        ) {
            if (this.props.collection.collection) {
                const { _id, name, url, description, category, user, logo, featuredImg, banner
                    , instaLink, siteLink, discordLink, mediumLink, telegramLink, payoutAddr, royalty, blockChain
                } = this.props.collection.collection;

                if(user._id !== ENV.getUserKeys()?._id){
                    this.props.navigate('/my-collections')
                }

                let collection = { ...this.state.collection };
                collection.name = name
                collection.description = description
                collection.url = url

                collection.categoryId = category?._id
                collection.categoryName = category?.name
                collection.userId = user._id
                collection.logoUrl = logo
                collection.featuredImgUrl = featuredImg
                collection.bannerUrl = banner
                collection._id = _id
                //after slug added new fields
                collection.siteLink = siteLink
                collection.discordLink = discordLink
                collection.instaLink = instaLink
                collection.mediumLink = mediumLink
                collection.telegramLink = telegramLink
                collection.royalty = royalty
                collection.payoutAddr = payoutAddr
                collection.blockChain = blockChain

                this.setState({ collection })
            }
        }
        // this.setChainId(); //Handle it as it gets dom heavy and stuck
    }

    setChainId = async () => {
        const chainId = await getChainId();

        this.setState({
            chainId
        });
    }
    bannerHandler = () =>{
        const collection=this.state.collection
        collection.bannerUrl=null;
        collection.banner=null;

        this.setState({
            collection  
              })

    }
    onFileChange(e) {
        let file = e.target.files[0];
        if (file)
            if (file.type.includes('image')) {
                let { collection } = this.state
                collection = { ...collection, [e.target.name]: file }
                if (e.target.name) {
                    collection[`${e.target.name}Url`] = URL.createObjectURL(e.target.files[0])
                    $(`#collection-${e.target.name}-label`).html('File selected')
                }
                this.setState({ collection })
            }
    }

    onChange(e) {
        let { name, value } = e.target
        let { collection } = this.state
        collection = { ...collection, [name]: value }
        this.setState({ collection }, () => {
            let formValid = true, siteUrlCheck = true, discordLinkCheck = true, telLinkCheck = true
            if (parseFloat(collection.royalty) > 10)
                formValid = false

            if (parseFloat(collection.royalty) <= 10 && collection.payoutAddr && collection.payoutAddr.length !== 42)
                formValid = false

            if (collection.url && !this.isValidUrl(collection.url))
                formValid = false

            if (collection.discordLink && !this.isValidUrl(collection.discordLink)) {
                formValid = false
                discordLinkCheck = false
            }

            if (collection.telegramLink && !this.isValidUrl(collection.telegramLink)) {
                formValid = false
                telLinkCheck = false
            }

            if (!this.validator.allValid())
                formValid = false

            if (!this.isSiteUrl(collection.siteLink)) {
                formValid = false
                siteUrlCheck = false
            }

            this.setState({ formValid, siteUrlCheck, discordLinkCheck, telLinkCheck })
        })
    }

    isSiteUrl = (link) => {
        if (link) {
            var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
            return !!pattern.test(link)
        }

        return true
    }

    isValidUrl = (userInput) => {
        var res = userInput.match(/^[A-Za-z0-9_?.=/#-]*$/);
        if (res == null)
            return false;
        else
            return true;
    }


    reset = () => {
        const collection = {
            userId: '',
            categoryId: '',
            logo: '',
            featuredImg: '',
            banner: '',
            name: '',
            url: '',
            description: '',
            siteLink: '',
            discordLink: '',
            instaLink: '',
            mediumLink: '',
            telegramLink: '',
            royalty: '',
            payoutAddr: ''
        }
        this.setState({ collection, isSubmitted: false })
    }

    submit = (e) => {
        e.preventDefault()
        let { collection } = this.state
        let formValid = true, siteUrlCheck = true
        // let discordLinkCheck = true, telLinkCheck = true     //defined but not used

        if (parseFloat(collection.royalty) > 10)
            formValid = false

        if (parseFloat(collection.royalty) <= 10 && collection.payoutAddr && collection.payoutAddr.length !== 42)
            formValid = false

        if (collection.url && !this.isValidUrl(collection.url))
            formValid = false

        if (collection.discordLink && !this.isValidUrl(collection.discordLink)) {
            formValid = false
            // discordLinkCheck = false     //defined but not used
        }

        if (collection.telegramLink && !this.isValidUrl(collection.telegramLink)) {
            formValid = false
            // telLinkCheck = false         //defined but not used
        }

        if (!this.validator.allValid())
            formValid = false

        if (!this.isSiteUrl(collection.siteLink)) {
            formValid = false
            siteUrlCheck = false
        }

        this.setState({ isSubmitted: true, formValid }, () => {
            const { formValid } = this.state
            if (formValid && siteUrlCheck) {
                this.setState({
                    loader: true,
                }, async () => {
                    const { collection } = this.state
                    var formData = new FormData()
                    for (const key in collection)
                        if (collection[key])
                            formData.append(key, collection[key])

                    this.props.upsertCollection('collection/edit', formData, 'PUT')
                })
            } else {
                this.validator.showMessages()
                $('html, body').animate({
                    scrollTop: $(".col-container").offset().top
                }, 500)

                this.setState({
                    errors: 'Please fill all required fields in valid format.',
                    formValid: false
                }, () => {
                    $('#create-collection').scrollTop(0, 0)
                })
            }
        })
    }

    render() {
        const { collection, errors, loader, isSubmitted, categories, siteUrlCheck, discordLinkCheck, telLinkCheck } = this.state
        let chainIds = Object.keys(chainsConfigs);
        return (
            <section className="author-area padding-wrapper edit-profile-section collection-edit-profile-area">
                <form id="create-collection">
                    {loader && <FullPageLoader />}
                    <div className="profile-container">
                        <div className="col-container row justify-content-between">
                            <div className='col-md-12'>
                            <div className="intro">
                                        <div className="intro-content">
                                            <div className='d-flex justify-content-center content  align-items-center flex-wrap'>
                                                <h3>Edit Collection</h3>
                                                
                                            </div>
                                            <p>Vivamus suscipit tortor eget felis porttitor volutpat. Donec rutrum congue leo eget malesuada</p>
                                        </div>
                                    </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="mt-5 mt-lg-0 mb-5 mb-lg-5">
                                <div className="profile-picture d-flex align-items-center mb-4 ">
                                    <div className="profile-img">
                                        <img id="logo-placeholder" className={collection.logoUrl ? "card-img-top" : "card-img-top create-collection-placeholder"} src={collection.logoUrl ? collection.logoUrl : collectionFeaturedImg} alt="" />
                                    </div>
                                    <div className='profile-img-data ml-3 '>
                                        <p>Recommended image size is 300x300 pixels. Gifs are  compactible upto a max of 5mb.</p>
                                        <div className="file-input pt-5 pt-sm-5">
                                            <input type="file" className="custom-file-input" id="logo" accept=".png,.jpeg,.jpg,.gif,image/gif" onChange={(e) => this.onFileChange(e)} name="logo" />
                                            <label className="file-input__label" htmlFor="logo" id="nft-image-label"><span>Upload file</span></label><span className="text-danger"></span>
                                        </div>

                                    </div>
                                    <span className="text-danger">{this.validator.message('logo', collection.logoUrl, 'required')}</span>
                                </div>
                                    {
                                        isSubmitted && errors &&
                                        <div className="row">
                                            <div className="col-12">
                                                <span id="create-collection-err" className="text-danger">{errors}</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                                
                                <div className="form-group custom-padding mb-5 ">
                                    <input type="text" className="form-control" name="name" placeholder="Name *" onChange={(e) => this.onChange(e)} defaultValue={collection.name} />
                                    <span className="text-danger">{this.validator.message('name', collection.name, 'required')}</span>
                                </div>
                                {this.state.collection?.categoryId && <div className="form-group mb-5">
                                    <span className="no-padding">
                                        <select className="form-control" id="category" name="categoryId" onChange={(e) => this.onChange(e)} value={this.state.collection?.categoryId}>
                                            {
                                                categories && categories.map((category, index) => {
                                                    return (
                                                        <option key={index} value={category._id}>{category.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </span>
                                    <span className="text-danger">{this.validator.message('category', collection.categoryId, 'required')}</span>
                                </div>}
                                <div className="form-group mb-5">
                                    {/* <label htmlFor="category">Select Category *</label> */}
                                    <span className="no-padding">
                                        <select className="form-control" id="blockChain" name="blockChain" value={collection.blockChain} onChange={(e) => this.onChange(e)}>
                                            <option value="">Select BlockChain</option>
                                            {
                                                chainIds && chainIds.map((chain, index) => {
                                                    return (
                                                        <option key={index} value={chainsConfigs[parseInt(chain)].number}>{chainsConfigs[parseInt(chain)].title}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </span>
                                    <span className="text-danger">{this.validator.message('BlockChain', collection.blockChain, 'required')}</span>
                                </div>



                                <div className="form-group mb-5">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon3">https://discord.gg/</span>
                                        </div>
                                        <input type="text" className="form-control" id="basic-url" aria-describedby="basic-addon3" placeholder="yourdiscordhandle" name="discordLink" onChange={(e) => this.onChange(e)} defaultValue={collection.discordLink} />
                                    </div>
                                    {
                                        !discordLinkCheck &&
                                        <span className="text-danger">Invalid discord URL.</span>
                                    }
                                </div>
                                <div className="form-group mb-5">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon3">https://www.medium.com/@</span>
                                        </div>
                                        <input type="text" className="form-control" id="basic-url" aria-describedby="basic-addon3" placeholder="YourMediumHandle" name="mediumLink" onChange={(e) => this.onChange(e)} defaultValue={collection.mediumLink} />
                                    </div>
                                </div>
                                <div className="form-group mb-5">
                                    <input role="presentation" autoComplete="off" type="text" className="form-control" name="royalty" placeholder="Royalty e.g. 2" onChange={(e) => this.onChange(e)} defaultValue={collection.royalty} onKeyDown={(e) => integerNumberValidator(e)}
                                    />
                                    {
                                        parseFloat(collection.royalty) > 10 &&
                                        <span className="text-danger">Royalty fee cannot be greater than 10%.</span>
                                    }
                                </div>

                                {
                                    parseFloat(collection.royalty) <= 10 &&
                                    <div className="col-12">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="payoutAddr" placeholder="Please enter an address, e.g. 0x1ed3..." onChange={(e) => this.onChange(e)} defaultValue={collection.payoutAddr} />
                                            <span className="text-danger">{this.validator.message('payoutAddr', collection.payoutAddr, 'required')}</span>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className='col-lg-6 col-md-12'>
                               
                                <div className="text-center  custom-choose-banner edit-collection-banner" style={collection.bannerUrl ? { border: "none" } : null}>
                                    <div className="image-over">
                                        <img id="banner-placeholder" className={collection.bannerUrl ? "card-img-top" : null} src={collection.bannerUrl ? collection.bannerUrl : null} alt="" />
                                        {collection.bannerUrl && <div class="choose-banner-overlay"><button onClick={this.bannerHandler} type="button" class="close-button" aria-label="Close">X</button></div>}
                                    </div>
                                    {collection.bannerUrl ? "" :  <div className=" choose-banner-input-group">
                                        <input type="file" className="custom-file-input" id="banner" accept=".png,.jpeg,.jpg,.gif,image/gif" onChange={(e) => this.onFileChange(e)} name="banner" />
                                        <label id="collection-banner-label"  htmlFor="banner">Choose Banner</label>
                                    </div>}
                                </div>
                                
                                <div className="form-group mb-5">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon3">{ENV.domainURL}collection/</span>
                                        </div>
                                        <input type="text" className="form-control" name="url" placeholder="URL"
                                            onChange={(e) => {
                                                this.onChange(e)
                                                if (e.target.value) {
                                                    if (!this.isValidUrl(e.target.value)) {
                                                        let { collection } = this.state
                                                        collection = { ...collection, url: e.target.value }
                                                        this.setState({
                                                            ...this.state,
                                                            urlMsg: 'Entered value must only contain lowercase letters, numbers, and hyphens in between text',
                                                            collection
                                                        })
                                                    }
                                                    else {
                                                        let { collection } = this.state
                                                        collection = { ...collection, url: e.target.value }
                                                        this.setState({
                                                            ...this.state,
                                                            urlMsg: '',
                                                            collection
                                                        })
                                                    }
                                                }
                                            }} defaultValue={collection.url} />
                                        {this.state.collection.url ? <label className={`text-danger pl-1 ${this.state.urlMsg ? `` : `d-none`}`}>{this.state.urlMsg}</label> : ''}                                            </div>
                                </div>
                                <div className="form-group mb-5">
                                    <input type="text" className="form-control" name="siteLink" placeholder="yoursite.io" onChange={(e) => this.onChange(e)} defaultValue={collection.siteLink} />
                                    {
                                        !siteUrlCheck &&
                                        <span className="text-danger">Invalid website URL.</span>
                                    }
                                </div>
                                <div className="form-group mb-5">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon3">https://www.instagram.com/</span>
                                        </div>
                                        <input type="text" className="form-control" id="basic-url" aria-describedby="basic-addon3" placeholder="YourInstagramHandle" name="instaLink" onChange={(e) => this.onChange(e)} defaultValue={collection.instaLink} />
                                    </div>
                                </div>
                                <div className="form-group mb-5">
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon3">https://t.me/</span>
                                        </div>
                                        <input type="text" className="form-control" id="basic-url" aria-describedby="basic-addon3" placeholder="yourtelegramhandle" name="telegramLink" onChange={(e) => this.onChange(e)} defaultValue={collection.telegramLink} />
                                    </div>
                                    {
                                        !telLinkCheck &&
                                        <span className="text-danger">Invalid telegram URL.</span>
                                    }
                                </div>
                                <div className="form-group mb-5">
                                    <textarea className="form-control" name="description" placeholder="Description" cols={30} rows={3} onChange={(e) => this.onChange(e)} defaultValue={collection.description} />
                                </div>
                                <div className='d-flex align-items-center justify-content-end flex-wrap create-edit-buttons'>
                            <button to="/my-collections" disabled={loader} className="btn  cancel-button mt-3 mt-sm-4">Cancel</button>
                            <button disabled={loader} className="btn edit-collection-btn  mt-3 mt-sm-4" type="button" onClick={(e) => this.submit(e)}>Update Collection</button>
                        </div>
                            </div>
                        </div>
                      
                    </div>
                </form>
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        collection: state?.collection,
        error: state.error,
        category: state.category
    }
};

export default connect(mapStateToProps, { beforeCollection, upsertCollection, emptyError, beforeCategory, getCategories, getCollection, deleteCollection })(EditCollection);