import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { beforeFaq, getFaq } from '../faq/faq.action';
import { connect } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/free-solid-svg-icons';

const WorksDetail = (props) => {
    const [data, setData] = useState(null)
    const faqId = window.location.pathname.split('/')[2]

    useEffect(() => {
        window.scroll(0, 0)
        props.getFaq(faqId)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.faq.faqAuth) {
            const { faq } = props.faq
            setData(faq)
            props.beforeFaq()
        }
    }, [props.faq.faqAuth]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {data &&
                <section className='works-detail'>
                    <Container>
                        <Row>
                            <Col md={12}>
                                <div className='linking-items d-flex align-items-center'>
                                <Link to={'/how-it-works'}><span>How it works</span></Link>
                                    <span><FontAwesomeIcon icon={faChevronRight}/><Link to=''> {data?.title}</Link></span>
                                </div>
                                <div className='works-custom-heading'>
                                    <h2>{data?.title}</h2>
                                </div>
                                <div className="card-body py-3" dangerouslySetInnerHTML={{ __html: data?.desc }}>
                                </div>
                            </Col>
                        </Row>


                    </Container>
                </section>
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    faq: state.faqs,
    error: state.error,
});

export default connect(mapStateToProps, {
    beforeFaq,
    getFaq
})(WorksDetail);