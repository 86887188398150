
import React, { useEffect } from 'react';
import './miniloader.css';

const MiniLoader = () => {
    return (
        <React.Fragment>
            <div className="mini-loader-holder">
                <div className="mini-loader mini-loader-image ">
                <div class="outer">
                        <div class="loader">
                            <span></span>
                            <p>Loading</p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MiniLoader