import { ENV } from './../../config/config';
import { SET_CHAT_LIST, SET_INDIVIDUAL_USER, BEFORE_USER, GET_ERRORS } from '../../redux/types';
import { emptyError } from '../../redux/shared/error/error.action';

export const beforeUser = () => {
    return {
        type: BEFORE_USER
    }
}

// method to get authors' details
export const getCreators = (qs = '') => dispatch => {
    dispatch(emptyError());
    let url = `${ENV.url}chat/list`

    if (qs)
        url += `?${qs}`

    fetch(url, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: SET_CHAT_LIST,
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};

// method to get authors' details
export const getUserById = (userId = '') => dispatch => {
    dispatch(emptyError());
    const url = `${ENV.url}users/${userId}`;
    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': ENV.Authorization,
            'x-auth-token': ENV.x_auth_token,
            'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
    }).then(res => res.json()).then(data => {
        if (data.status) {
            dispatch({
                type: SET_INDIVIDUAL_USER,
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_ERRORS,
                payload: data
            })
        }
    }).catch(errors => {
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    })
};