import React from 'react'
import '../custom-heading/custom-heading.css';
function CustomHeading(props) {
  return (
   <>
   <div className='custom-heading'>
       <h2><span>{props.heading}</span> <span className="collecion-tag">{props.collection}</span></h2>
   </div>
   </>
  )
}

export default CustomHeading