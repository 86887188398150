// layouts
import layout1 from "./layouts/layout1/layout1";
import layout2 from "./layouts/layout2/layout2";
import layout4 from "./layouts/layout4/layout4";
import layout5 from "./layouts/layout5/layout5";
// components
import Template from "./components/template/template";
import ItemDetails from "./components/item-detail/item-detail";
import HelpCenter from "./components/help-center/help-center";
import Authors from "./components/authors-list/authors-list";
import Author from "./components/author/author";
import Profile from "./components/profile/profile";
import GetStarted from "./components/home/get-started/GetStarted";
import CreateNFT from "./components/create-nft/create-nft";
import MyCollections from "./components/collections/my-collections";
import AllCollections from "./components/collections/all-collections";
import CreateCollection from "./components/create-collection/create-collection";
import EidtCollection from "./components/create-collection/edit-collection";
import CollectionDetails from "./components/collections/collection-details";
import Wallet from "./components/wallet/wallet";
import Contact from "./components/contact/contact";
import SellNFT from "./components/nfts/sell-nft";
import NotFound from "./components/not-found/not-found";
import HowItWorks from "./components/how-it-works/how-it-works";
import HowItWorksDetail from "./components/how-it-works/how-it-works-details";
import About from "./components/about/about";
import PrivacyTerms from "./components/privacy-&-terms/privacy-&-terms";
import Trading from "./components/trading/trading";
import ExploreCategory from "./components/categories/explore-category";
import ChooseBlockchain from "./components/choose-blockchain/choose-blockchain";
import ChooseMint from "./components/choose-mint/choose-mint";
import ExploreMarketplace from "./components/explore-marketplace/explore-marketplace";
import Chat from "./components/chat/Chat";
import VerifyEmail from "./components/verify-email/VerifyEmail";
import RedirectLink from "./components/redirect-page/Redirect";
import TermCondition from "./components/terms-condition/TermCondition";
import Order from "./components/order/order";
// routes
const routes = [
  {
    path: "/",
    access: true,
    exact: true,
    title: "Template",
    layout: layout4,
    component: Template,
  },
  {
    path: "/explore-all",
    access: true,
    exact: true,
    title: "Explore",
    layout: layout2,
    component: ExploreMarketplace,
  },
  {
    path: "/item-details/:item",
    access: true,
    exact: true,
    title: "Item Details",
    layout: layout2,
    component: ItemDetails,
  },
  {
    path: "/sell-item/:itemId",
    access: true,
    exact: true,
    title: "Sell Item",
    layout: layout2,
    component: SellNFT,
  },
  {
    path: "/trading-history",
    access: true,
    exact: true,
    title: "Trading",
    layout: layout2,
    component: Trading,
  },
  {
    path: "/orders",
    access: true,
    exact: true,
    title: "Orders",
    layout: layout2,
    component: Order,
  },
  {
    path: "/help-center",
    access: true,
    exact: true,
    title: "Help Center",
    layout: layout1,
    component: HelpCenter,
  },
  {
    path: "/artists",
    access: true,
    exact: true,
    title: "Artists",
    layout: layout2,
    component: Authors,
  },
  {
    path: "/artist/:authorId",
    access: true,
    exact: true,
    title: "Artist Profile",
    layout: layout1,
    component: Author,
  },
  {
    path: "/profile",
    access: true,
    exact: true,
    title: "Profile",
    layout: layout2,
    component: Profile,
  },
  {
    path: "/create/:blockChain/:mintType",
    access: true,
    exact: true,
    title: "Create",
    layout: layout2,
    component: CreateNFT,
  },
  {
    path: "/my-collections",
    access: true,
    exact: true,
    title: "Collections",
    layout: layout2,
    component: MyCollections,
  },
  {
    path: "/collection/create",
    access: true,
    exact: true,
    title: "Create Collection",
    layout: layout2,
    component: CreateCollection,
  },
  {
    path: "/collections",
    access: true,
    exact: true,
    title: "Collections",
    layout: layout2,
    component: AllCollections,
  },
  {
    path: "/collection/edit/:id",
    access: true,
    exact: true,
    title: "Create Collection",
    layout: layout2,
    component: EidtCollection,
  },
  {
    path: "/collection/:collectionId",
    access: true,
    exact: true,
    title: "Collection Details",
    layout: layout1,
    component: CollectionDetails,
  },
  {
    path: "/login",
    access: true,
    exact: true,
    title: "login",
    layout: layout5,
    component: Wallet,
  },
  {
    path: "/terms-conditions",
    access: true,
    exact: true,
    title: "Terms & Conditions",
    layout: layout1,
    component: TermCondition,
  },
  {
    path: "/contact",
    access: true,
    exact: true,
    title: "Contact",
    layout: layout1,
    component: Contact,
  },
  {
    path: "/how-it-works",
    access: true,
    exact: true,
    title: "How It Works",
    layout: layout2,
    component: HowItWorks,
  },
  {
    path: "/how-it-works/:faqId",
    access: true,
    exact: true,
    title: "How It Works",
    layout: layout2,
    component: HowItWorksDetail,
  },
  {
    path: "/about",
    access: true,
    exact: true,
    title: "About",
    layout: layout1,
    component: About,
  },
  {
    path: "/get-started",
    access: true,
    exact: true,
    title: "Get Started",
    layout: layout2,
    component: GetStarted,
  },
  {
    path: "/privacy-and-terms",
    access: true,
    exact: true,
    title: "Privacy & Terms",
    layout: layout1,
    component: PrivacyTerms,
  },
  {
    path: "/category/:slug",
    access: true,
    exact: true,
    title: "Explore Category",
    layout: layout1,
    component: ExploreCategory,
  },
  {
    path: "/choose-blockchain",
    access: true,
    exact: true,
    title: "Choose Blockchain",
    layout: layout2,
    component: ChooseBlockchain,
  },
  {
    path: "/choose-mint/:blockChain",
    access: true,
    exact: true,
    title: "Choose Mint Type",
    layout: layout2,
    component: ChooseMint,
  },
  {
    path: "/live-chat",
    access: true,
    exact: true,
    title: "Chat",
    layout: layout4,
    component: Chat,
  },
  {
    path: "/verify-email/:token",
    access: true,
    exact: true,
    title: "Verify Email",
    layout: layout2,
    component: VerifyEmail,
  },
  {
    path: "/redirect-link",
    access: true,
    exact: true,
    title: "Redirect Link",
    layout: layout2,
    component: RedirectLink,
  },
  {
    path: "/*",
    access: true,
    exact: true,
    name: "Not Found",
    layout: layout2,
    component: NotFound,
  },
];

export default routes;
