import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { beforeNFT, getNFTs, favoriteToggle } from '../nfts/nfts.action';
import { ENV } from '../../config/config';
import { Link } from 'react-router-dom';
import YellowChecked from '../../assets/images/tik.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'

import Ethereum from '../../assets/images/arow-icon.png';
import Binance from '../../assets/images/bnb.png';
import Fantom from '../../assets/images/fantom.png';
import Cronos from '../../assets/images/cronos.png';
import { useNavigate } from "react-router-dom";

const initData = {
    btn_1: "Load More",
}

function ExploreItems(props) {
    const [nftPagination, setNFTPagination] = useState(null);
    const [nfts, setNFTs] = useState([]); // NFTs for explore section
    const [loader, setLoader] = useState(true);
    const [loadMoreBtn, setLoadMoreBtn] = useState(false);
    const [check, setCheck] = useState(false);
    const userId = ENV.getUserKeys()?._id ? ENV.getUserKeys()?._id : undefined
    const navigate = useNavigate();

    useEffect(() => {
        // get NFTs for explore section
        const nftQS = { page: 1, limit: props.limit || 4, explore: true }
        if (props.creatorId)
            nftQS.creatorId = props.creatorId
        if (props.collectionId)
            nftQS.collectionId = props.collectionId
        if (props.previouslySold)
            nftQS.previouslySold = props.previouslySold
        if (props.mostSold)
            nftQS.mostSold = props.mostSold

        const qs = ENV.objectToQueryString(nftQS)
        props.getNFTs(qs)
        if (props.setLoader) {
            props.setLoader(true)
        }
    }, [])

    // set NFTs for explore section
    useEffect(() => {
        if (props.nft.nftsAuth
            && nfts.length == 0  //dure to concurent scenarion handling in for about-nfts call
        ) {
            const { nftsData } = props.nft
            if (nftsData) {
                setNFTs(nftsData.nfts)
                setNFTPagination(nftsData.pagination)
                props.beforeNFT()
                setLoader(false)
                if (props.mostSold)
                    props.setX(true)
                if (props.setView)
                    props.setViewAll(true)

                if (props.setLoader) {
                    props.setLoader(false)
                }
                // props.setViewAll(nftsData.nfts && nftsData.nfts.length > 0)
            }
        }

    }, [props.nft.nftsAuth])

    // handle show load more button state when pagination is set
    useEffect(() => {
        if (nftPagination)
            setLoadMoreBtn((nftPagination.total > 0 && nftPagination.total > nfts.length))
    }, [nftPagination])

    const handleFavouriteClick = (itemId) => {
        const data = {
            nftId: itemId
        }
        setCheck(true)
        props.favoriteToggle(data)
    }

    useEffect(() => {
        if (props.nft.favoriteNftAuth && check) {
            updateNfFavorite(props.nft.favoriteNft?._id, props.nft.isFavourite, props.nft.likesCount)
            setCheck(false)
        }

    }, [props.nft.favoriteNftAuth])

    const updateNfFavorite = async (id, isFavorite, likesCount) => {
        const seletedNFT = await nfts.find(item => (item._id === id))
        seletedNFT.isLike = isFavorite;
        seletedNFT.totalLikes = likesCount
        props.beforeNFT()
    }

    const loadMore = () => {
        const { page, limit } = nftPagination
        setLoader(true)

        // get more NFTs for explore section
        const nftQS = { page: 1, limit: limit * (page + 1), explore: true }
        const qs = ENV.objectToQueryString(nftQS)
        props.getNFTs(qs)
    }

    return (
        <>
            <div className={`items ${props.class1 ? props.class1 : ''} ${!nfts.length ? 'justify-content-center' : ''}`}>
                {
                    nfts && nfts.length > 0 ?
                        nfts.map((item, idx) => {
                            return (
                                <div key={`${props.key}_${idx}`} className={`item d-block ${props.class2 ? props.class2 : ''}`}>
                                    <div className="card">
                                        <div className="image-over">
                                            <Link to={`/item-details/${window.btoa(item._id)}`}>
                                                <img className="card-img-top" src={item.image} alt="Item Image" />
                                            </Link>
                                        </div>
                                        <div className="card-caption">
                                            <div className="card-body">
                                                <div className="nft-name d-flex justify-content-between align-items-center">
                                                    <Link to={`/item-details/${window.btoa(item._id)}`} className="d-flex align-items-center">
                                                        <h5 className="mb-0">{item.name}</h5>

                                                        {/* {item?.tokenId >= 0 && <span className="number ml-2">#{item.tokenId}</span>}\ */}

                                                    </Link>
                                                    <div className="likes-section d-flex align-items-center">
                                                        <span className='heart-icon {active}'>
                                                            {item?.isLike ?
                                                                <FontAwesomeIcon onClick={() => handleFavouriteClick(item._id)} icon={faHeart} style={{ "color": "red" }} /> : <FontAwesomeIcon onClick={() => handleFavouriteClick(item._id)} icon={faHeart} />
                                                            }
                                                        </span>
                                                        {item?.totalLikes >= 0 && <span className='likes-count ml-1'>{item?.totalLikes}</span>}
                                                    </div>
                                                </div>
                                                <div className='nft-name d-flex align-items-center'>
                                                    <Link to={`/artist/${item?.owner?.username}`}>{item?.owner?.username}</Link>
                                                    {item?.owner?.adminVerified === 1 && <span className='banner-checked ml-1'><img className="img-fluid" src={YellowChecked} /></span>}
                                                </div>
                                               
                                                <div className="biding-section">
                                                    <div className="biding-section-in d-flex align-items-center justify-content-between ">
                                                        {item?.currentPrice >= 0 && <div className="biding-data">
                                                            <span className="purple-span">Price</span>
                                                            <span className="price float-right">{item.currentPrice} {item?.currency}</span>
                                                        </div>}

                                                        {item?.topBid >= 0 ?
                                                            (<div className="biding-data ml-3">
                                                                <span className="purple-span">Highest Bid</span>
                                                                <span className="price float-right">{item?.topBid} {item?.topBidCurrency}</span>
                                                                <img className="img-fluid custom-chain-icon" src={ENV.chainsConfigs[ENV.numberToChainId[item?.blockChain]]?.currencyImage} />
                                                            </div>)
                                                            :
                                                            item?.lastPrice?.price >= 0 ?
                                                                (<div className="biding-data ml-3">
                                                                    <span className="purple-span">Last Price</span>
                                                                    <span className="price">{item.lastPrice.price} {item.lastPrice.currency}</span>
                                                                    <img className="img-fluid custom-chain-icon" src={ENV.chainsConfigs[ENV.numberToChainId[item?.blockChain]]?.currencyImage} />
                                                                </div>) :
                                                                <img className="img-fluid custom-chain-icon" src={ENV.chainsConfigs[ENV.numberToChainId[item?.blockChain]]?.currencyImage} />}



                                                    </div>
                                                   
                                                </div>

                                                <div className="buy-section text-center" onClick={() => navigate(`/item-details/${window.btoa(item._id)}`)}>
                                                    {item.sellingMethod ?
                                                        (userId == item?.owner?._id
                                                            ? <Link className="buy-btn" to={`/item-details/${window.btoa(item._id)}`}>{"View Details"}</Link>
                                                            : <Link className="buy-btn" to={`/item-details/${window.btoa(item._id)}`}>{(item?.sellingMethod) === 1 ? "Buy Now" : (item?.sellingMethod) === 2 ? "Place A Bid" : ""}</Link>)
                                                        :
                                                        (userId == item?.owner?._id
                                                            ? <Link className="buy-btn" to={`/item-details/${window.btoa(item._id)}`}>{"List to sale/bid"}</Link>
                                                            : <Link className="buy-btn" to={`/item-details/${window.btoa(item._id)}`}>{"Not for sale"}</Link>)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                        : <div className="no-data border"><p className="text-center">No Items Found to Explore</p></div>
                }
            </div>
            {
                loadMoreBtn &&
                props?.loadMore !== false &&
                <div className="row">
                    <div className="col-12 text-center">
                        <a id="load-btn" className="btn btn-danger offer  mt-5" onClick={() => loadMore()}>{initData.btn_1}</a>
                    </div>
                </div>
            }
        </>
    );
}

const mapStateToProps = state => ({
    nft: state.nft
});

export default connect(mapStateToProps, { beforeNFT, getNFTs, favoriteToggle })(ExploreItems);
