import React from 'react'
import { useEffect,useRef } from 'react'
import {Row,Container} from 'react-bootstrap'
import animatedM from '../../assets/images/animated-m.png'
import mRK from '../../assets/images/mrk.png'
import './main-page.css'
import ScrollTrigger from "gsap/ScrollTrigger";
import gsap from "gsap";
gsap.registerPlugin(ScrollTrigger);

const MainPage = () => {

  const tl = gsap.timeline({
    // scrollTrigger: {
    //     trigger: ".animee",
    //     start: "top",
    //     end: "bottom bottom",
    //     scrub: false,
    //     pin:true,
    //     // toggleActions: "play none none reverse"
    // },
});
useEffect(() => {
  tl.to(".main,.daynim-header", {autoAlpha:0});
  tl.to(".animated-m img", { scale: 2, duration: 4});
  tl.to(".mrk-image img", {  width:"80px",autoAlpha:1,display:"block",ease:"bounce.out",duration: 3},'-=3');
  tl.to(".mrk-image img", {  width:"120px",ease:"bounce.in",duration: 3},'-=3');
  tl.to(".animated-m img", { scale: 3, duration: 4,autoAlpha:0},'-=3');
  tl.to(".mrk-image img", {  width:"100%",height:"100vh",ease:"bounce.in",duration: 3},'-=3');
  tl.to(".mrk-image img", {  autoAlpha:0});
  tl.to(".animee", {  display:"none"});
  tl.to(".main,.daynim-header", {autoAlpha:1});
  
 
}, [])

  return (
    <>
    <section className='animee'>
      <Container>
        <Row>
          <div className='mrk-image'>
          <img src={mRK} className='  img-fluid' id='mrk' alt='image'/>
          </div>
         <div className='animated-m'>
         <img src={animatedM} className=' ref={circleRef} img-fluid' id='animated-m' alt='image'/>
         </div>
        </Row>
      </Container>
    </section>
    </>  
    )
}

export default MainPage




