import Ethereum from "../assets/images/arow-icon.png";
import Binance from "../assets/images/binancelogo.png";
import Fantom from "../assets/images/fantom.png";
import Cronos from "../assets/images/cronos.png";
require("dotenv").config();
const CryptoJS = require("crypto-js");
const dataEncryptionKey = "kalsaOOLLaASASAFFSSEE";
const moment = require("moment");
const { toast } = require("react-toastify");

export const ENV = {
  pixulToken: process.env.REACT_APP_PIXUL_TOKEN,
  transferFundUrl: {
    ethFundTranfer: process.env.REACT_APP_ETHEREUM_FUND_TRANSFER,
    fantomFundTranfer: process.env.REACT_APP_FANTOM_FUND_TRANSFER,
    cronosFundTranfer: process.env.REACT_APP_CRONOS_FUND_TRANSFER,
    binanceFundTranfer: process.env.REACT_APP_BINANCE_FUND_TRANSFER,
  },
  defaultChainId: parseInt(process.env.REACT_APP_REQUIRED_CHAIN),
  ethMainRPCURL:
    "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  // numberToChainId: {
  //     4: 25,
  //     3: 250,
  //     2: 56,
  //     1: 1,
  // },
  // chainsConfigs: {
  //     1: {
  //         number: 1,
  //         nativeCurrency: {
  //             name: 'Ethereum',
  //             symbol: 'ETH',
  //             decimals: 18,
  //         },
  //         rpcUrl: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  //         explorer: "https://etherscan.io/",
  //         eventKey: 'Ethereum',
  //         title: 'Ethereum',
  //         networkName: 'Ethereum',
  //         currencyImage: Ethereum,
  //         marketplaceAddress: "0x3eb0c8A43530f0AB82977657055212d045429ed4",
  //         NFT721Address: "0xEAdCf1eFe7a1e1CBF493ebb0e140658239D478b1",
  //         NFT1155Address: "0x7e0bC5B33B5156735Df4b33b72975bf44F0f6743"
  //     },
  //     56: {
  //         number: 2,
  //         nativeCurrency: {
  //             name: 'Binance',
  //             symbol: 'BNB',
  //             decimals: 18,
  //         },
  //         rpcUrl: "https://bsc-dataseed.binance.org/",
  //         explorer: "https://bscscan.com/",
  //         eventKey: 'Binance',
  //         title: 'Binance',
  //         networkName: 'Smart Chain',
  //         currencyImage: Binance,
  //         marketplaceAddress: "0x4a9c2d7250b0F2703b677B34552857558ABB8887",
  //         NFT721Address: "0xce985dCe85cCdb199984974b183D47dfc014aca7",
  //         NFT1155Address: "0x7e0bC5B33B5156735Df4b33b72975bf44F0f6743"
  //     },
  //     250: {
  //         number: 3,
  //         nativeCurrency: {
  //             name: 'Fantom',
  //             symbol: 'FTM',
  //             decimals: 18,
  //         },
  //         rpcUrl: 'https://rpc.fantom.network/',
  //         explorer: "https://ftmscan.com/",
  //         eventKey: 'Fantom',
  //         title: 'Fantom',
  //         networkName: 'Fantom',
  //         currencyImage: Fantom,
  //         marketplaceAddress: "0xB4A9DF4Ea279CbCdB346256d621688Ef1903Cb69",
  //         NFT721Address: "0xEAdCf1eFe7a1e1CBF493ebb0e140658239D478b1",
  //         NFT1155Address: "0x7e0bC5B33B5156735Df4b33b72975bf44F0f6743"
  //     },
  //     25: {
  //         number: 4,
  //         nativeCurrency: {
  //             name: 'Cronos',
  //             symbol: 'CRO',
  //             decimals: 18,
  //         },
  //         rpcUrl: "https://evm.cronos.org",
  //         explorer: "https://cronoscan.com/",
  //         eventKey: 'Cronos',
  //         title: 'Cronos',
  //         networkName: 'Cronos',
  //         currencyImage: Cronos,
  //         marketplaceAddress: "0x1f168d8d78b4cfeD660ed5b15Ad9afE5e3478E7A",
  //         NFT721Address: "0xEAdCf1eFe7a1e1CBF493ebb0e140658239D478b1",
  //         NFT1155Address: "0x7e0bC5B33B5156735Df4b33b72975bf44F0f6743"
  //     }
  // },

  numberToChainId: {
    4: 338,
    3: 4002,
    2: 97,
    1: 5,
  },

  chainsConfigs: {
    5: {
      number: 1,
      nativeCurrency: {
        name: "Goerli",
        symbol: "ETH",
        decimals: 18,
      },
      rpcUrl: "https://goerli.infura.io/v3/5272532ee1fa4c23a1cbe8ca18895cfc",
      explorer: "https://goerli.etherscan.io/",
      eventKey: "Ethereum",
      title: "Ethereum",
      networkName: "Goerli Testnet",
      currencyImage: Ethereum,
      // marketplaceAddress: "0xfAd5d2936b6F2A2f82F9BAA276DF5eC9FdfdB59D",
      marketplaceAddress: "0x7251D0fdff3e8CF95990DA151f524663c4074BaE",
      NFT721Address: "0xf5633C61b78f8705Ce15e471bd16b17E17D6f115",
      NFT1155Address: "0x5d706f9ea09aec76e8b89fbb3fd12bdabb26f0a6",
      api: {
        url: "https://api-goerli.etherscan.io/api",
        key: "YR6Z6SBFG8IB1THTW45YGFFYWKMI47ZW7H",
      },
    },
    97: {
      number: 2,
      nativeCurrency: {
        name: "Binance",
        symbol: "BNB",
        decimals: 18,
      },
      rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545",
      explorer: "https://testnet.bscscan.com/",
      eventKey: "Binance",
      title: "Binance",
      networkName: "Binance Testnet",
      currencyImage: Binance,
      marketplaceAddress: "0x6017c423e1076ebdf0fda21ff9b21ba69e31a34f",
      NFT721Address: "0x0a3f7d2d4f737507babce8f3246b5bc929d23258",
      NFT1155Address: "0xe63d2ba63d19094343f4d7b4946ada2483274cc8",
      api: {
        url: "https://api-testnet.bscscan.com/api",
        key: "2ZUW7BV1JWB8PGI4ARXVIB6R7KIX35J1ME",
      },
    },
    4002: {
      number: 3,
      nativeCurrency: {
        name: "Fantom",
        symbol: "FTM",
        decimals: 18,
      },
      rpcUrl: "https://rpc.testnet.fantom.network",
      explorer: "https://testnet.ftmscan.com/",
      eventKey: "Fantom",
      title: "Fantom",
      networkName: "Fantom Testnet",
      currencyImage: Fantom,
      marketplaceAddress: "0xe01108cebe4709ed35d8a1a21f14580130a7e202",
      NFT721Address: "0xdea61e1e59c96d0975e1fc7d1bce41270e93dd3d",
      NFT1155Address: "0xe7c0b9b1902a2d0785e67596f08e36d5a230a8ff",
      api: {
        url: "https://api-testnet.ftmscan.com/api",
        key: "8VAFYTTMHXERVVI8U2BYFPGUSKDUNQIQRX",
      },
    },
    338: {
      number: 4,
      nativeCurrency: {
        name: "Cronos",
        symbol: "CRO",
        decimals: 18,
      },
      rpcUrl: "https://evm-t3.cronos.org",
      explorer: "https://testnet.cronoscan.com/",
      eventKey: "Cronos",
      title: "Cronos",
      networkName: "Cronos Testnet",
      currencyImage: Cronos,
      marketplaceAddress: "0x0c4e452F873B2F6711986ef0F86092Ae28b436CB",
      // marketplaceAddress: "0x2f04a34424868fe26e61edd86e715eb1d57cd4a0",
      NFT721Address: "0xe610b339302eb1c01f8c6c8bc7469fc9a22b77bb",
      NFT1155Address: "0x4a464263621c92315f70ab6b6d3f718dd488d3fa",
      api: {
        url: "https://api-testnet.cronoscan.com/api",
        key: "F2YNNJ5TZ8NE66S2QW2M6C26Z6F16I96V3",
      },
    },
  },
  chainId: process.env.REACT_APP_REQUIRED_CHAIN,
  domainURL: process.env.REACT_APP_DOMAIN_URL,
  baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
  url: process.env.REACT_APP_BASE_URL,
  currency: {
    25: "CRO",
    56: "BNB",
    97: "BNB",
    250: "FTM",
    338: "CRO",
    4002: "FTM",
    1: "ETH",
    5: "ETH",
  },
  appName: process.env.REACT_APP_NAME,
  requiredChainName: {
    338: "Cronos Testnet",
    25: "Cronos",
    56: "Binance",
    97: "Binance Testnet",
    250: "Fantom",
    4002: "Fantom Testnet",
    1: "Ethereum",
    5: "Ethereum Testnet",
  },
  amountToApprove:
    "115792089237316195423570985008687907853269984665640564039457584007913129639935",
  currencyFilters: [
    {
      name: "Ethereum",
      label: "ETH",
      symbol: "ETH",
      value: 1,
      icon: "assets/images/ethereum.svg",
      chainIds: [1, 5],
    },
    {
      name: "Binance",
      label: "BNB",
      symbol: "BNB",
      value: 2,
      icon: "assets/images/binance.svg",
      chainIds: [56, 97],
    },
    {
      name: "Fantom",
      label: "FTM",
      symbol: "FTM",
      value: 3,
      icon: "assets/images/fantom.png",
      chainIds: [250, 4002],
    },
    {
      name: "Cronos",
      label: "CRO",
      symbol: "CRO",
      value: 4,
      icon: "assets/images/cronos.svg",
      chainIds: [25, 338],
    },
    // keep it at last index always
    {
      name: "US Dollars",
      label: "USD",
      symbol: "USD",
      value: -1,
      icon: "assets/images/usd.png",
      chainIds: null,
    },
  ],
  currencies: [
    {
      label: "WBNB",
      symbol: "WBNB",
      value: "WBNB",
      icon: "assets/images/binance.svg",
      showInBuy: true, // show in make an offer / place a bid modal
      address: process.env.REACT_APP_WBNB_TOKEN,
    },
    {
      label: "WETH",
      symbol: "WETH",
      value: "WETH",
      icon: "assets/images/binance.svg",
      showInBuy: true, // show in make an offer / place a bid modal
      address: process.env.REACT_APP_WETH_TOKEN,
    },
    {
      label: "WCRO",
      symbol: "WCRO",
      value: "WCRO",
      icon: "assets/images/cronos.svg",
      showInBuy: true, // show in make an offer / place a bid modal
      address: process.env.REACT_APP_WCRO_TOKEN,
    },
    {
      label: "WFTM",
      symbol: "WFTM",
      value: "WFTM",
      icon: "assets/images/binance.svg",
      showInBuy: true, // show in make an offer / place a bid modal
      address: process.env.REACT_APP_WFTM_TOKEN,
    },
    // {
    //     label: 'BNB',
    //     symbol: 'BNB',
    //     value: 'BNB',
    //     icon: 'assets/images/binance.svg',
    //     showInBuy: true, // show in make an offer / place a bid modal
    //     address: process.env.REACT_APP_WBNB_TOKEN,
    //     abi: wbnbContractAbi
    // },
    // {
    //     label: 'ETH',
    //     symbol: 'ETH',
    //     value: 'ETH',
    //     icon: 'assets/images/binance.svg',
    //     showInBuy: true, // show in make an offer / place a bid modal
    //     address: process.env.REACT_APP_WBNB_TOKEN,
    //     abi: wbnbContractAbi
    // },
    // {
    //     label: 'FTM',
    //     symbol: 'FTM',
    //     value: 'FTM',
    //     icon: 'assets/images/binance.svg',
    //     showInBuy: true, // show in make an offer / place a bid modal
    //     address: process.env.REACT_APP_WBNB_TOKEN,
    //     abi: wbnbContractAbi
    // },
    // {
    //     label: 'CRO',
    //     symbol: 'CRO',
    //     value: 'CRO',
    //     icon: 'assets/images/cronos.svg',
    //     showInBuy: true, // show in make an offer / place a bid modal
    //     address: process.env.REACT_APP_WBNB_TOKEN,
    //     abi: wcroContractAbi
    // },
  ],
  // Headers
  Authorization: `Bearer ${process.env.REACT_APP_AUTHORIZATION}`,
  x_auth_token: process.env.REACT_APP_X_AUTH_TOKEN,
  // default images placeholders
  globalPlaceholderImage: "/img/placeholder.jpg",
  collectionFeaturedImg: "/img/elumntstandardcollectionplaceholder.jpg", //Correct
  userDefaultImg: "/img/avatarplaceholder.jpg", //Correct
  categoryDefaultImg: "/img/placeholder.jpg",

  //set user in local storage
  encryptUserData: function (data) {
    let userData = localStorage.getItem("encuse");
    if (userData && !data.accessToken) {
      let bytes = CryptoJS.AES.decrypt(userData, dataEncryptionKey);
      let originalData = bytes.toString(CryptoJS.enc.Utf8);
      originalData = JSON.parse(originalData);
      if (originalData && originalData.accessToken) {
        data.accessToken = originalData.accessToken;
      }
    }
    data = JSON.stringify(data);
    let encryptedUser = CryptoJS.AES.encrypt(
      data,
      dataEncryptionKey
    ).toString();
    localStorage.setItem("encuse", encryptedUser);
    return true;
  },

  //return required keys
  getUserKeys: function (keys = null) {
    let userData = localStorage.getItem("encuse");
    if (userData) {
      var bytes = CryptoJS.AES.decrypt(userData, dataEncryptionKey);
      var originalData = bytes.toString(CryptoJS.enc.Utf8);
      originalData = JSON.parse(originalData);
      let user = {};
      if (keys) {
        keys = keys.split(" ");
        for (let key in keys) {
          let keyV = keys[key];
          user[keyV] = originalData[keyV];
        }
      } else {
        user = originalData;
      }
      return user;
    }
    return {};
  },

  //Object to query string
  objectToQueryString: function (body) {
    const qs = Object.keys(body)
      .map((key) => `${key}=${body[key]}`)
      .join("&");
    return qs;
  },

  //validate image types
  isValidImageType: function (file) {
    if (file && file.type) {
      const acceptableTypes = [
        "image/png",
        "image/x-png",
        "image/jpeg",
        "image/jpg",
      ];
      return acceptableTypes.includes(file.type.toLowerCase());
    }
  },

  //slick configurations
  slickSettings: {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          margin: 15,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  },

  dateRangeInitialSettings: {
    startDate: moment(),
    endDate: moment().add(6, "months").toDate(),
    minDate: moment(),
    maxDate: moment().add(6, "months").toDate(),
    ranges: {
      "1 Day": [moment().toDate(), moment().add(1, "days").toDate()],
      "3 Days": [moment().toDate(), moment().add(3, "days").toDate()],
      "1 Week": [moment().toDate(), moment().add(6, "days").toDate()],
    },
  },

  countDownRenderer: ({ days, hours, minutes, seconds }) => {
    return (
      <>
        <div className="countdown d-flex  justify-content-start  align-items-center mb-2">
          <div className="values">
            <h4>Days</h4>
            <p>{days}</p>
          </div>
          <div className="values ml-5">
            <h4>Hours</h4>
            <p>{hours}</p>
          </div>
          <div className="values ml-5">
            <h4>Minutes</h4>
            <p>{minutes}</p>
          </div>
          <div className="values ml-5">
            <h4>Seconds</h4>
            <p>{seconds}</p>
          </div>
        </div>
      </>
    );
  },

  decimalNumberValidator: function (e) {
    // Allow: backspace, delete, tab, escape, enter and .
    let specialKeys = [46, 8, 9, 27, 13, 110, 190];

    if (e.target.value.includes(".")) {
      specialKeys = [46, 8, 9, 27, 13];
    } else {
      specialKeys = [46, 8, 9, 27, 13, 110, 190];
    }

    // Allow: Ctrl+A,Ctrl+C,Ctrl+V, Command+A
    if (
      specialKeys.includes(e.keyCode) ||
      // Allow: Ctrl+A,Ctrl+C,Ctrl+Z,Ctrl+X Command+A
      ((e.keyCode === 65 ||
        e.keyCode === 67 ||
        e.keyCode === 90 ||
        e.keyCode === 88) &&
        (e.ctrlKey === true || e.metaKey === true)) ||
      // Allow: home, end, left, right, down, up
      (e.keyCode >= 35 && e.keyCode <= 40) ||
      // Allow F1 to F12 keys
      (e.keyCode >= 112 && e.keyCode <= 123)
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  },

  integerNumberValidator: function (e) {
    // Allow: backspace, delete, tab, escape, enter and .
    const specialKeys = [46, 8, 9, 27, 13];

    // Allow: Ctrl+A,Ctrl+C,Ctrl+V, Command+A
    if (
      specialKeys.includes(e.keyCode) ||
      // Allow: Ctrl+A,Ctrl+C,Ctrl+Z,Ctrl+X Command+A
      ((e.keyCode === 65 ||
        e.keyCode === 67 ||
        e.keyCode === 90 ||
        e.keyCode === 88) &&
        (e.ctrlKey === true || e.metaKey === true)) ||
      // Allow: home, end, left, right, down, up
      (e.keyCode >= 35 && e.keyCode <= 40)
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  },

  //convert mongoose exponent floating value into valid values
  exponentialToDecimal: (exponential) => {
    let decimal = exponential.toString().toLowerCase();
    if (decimal.includes("e+")) {
      const exponentialSplitted = decimal.split("e+");
      let postfix = "";
      for (
        let i = 0;
        i <
        +exponentialSplitted[1] -
          (exponentialSplitted[0].includes(".")
            ? exponentialSplitted[0].split(".")[1].length
            : 0);
        i++
      ) {
        postfix += "0";
      }
      const addCommas = (text) => {
        let j = 3;
        let textLength = text.length;
        while (j < textLength) {
          text = `${text.slice(0, textLength - j)},${text.slice(
            textLength - j,
            textLength
          )}`;
          textLength++;
          j += 3 + 1;
        }
        return text;
      };
      decimal = addCommas(exponentialSplitted[0].replace(".", "") + postfix);
    }
    if (decimal.toLowerCase().includes("e-")) {
      const exponentialSplitted = decimal.split("e-");
      let prefix = "0.";
      for (let i = 0; i < +exponentialSplitted[1] - 1; i++) {
        prefix += "0";
      }
      decimal = prefix + exponentialSplitted[0].replace(".", "");
    }
    return parseFloat(decimal);
  },

  convertXtoY: (val, rateUnit, qty = 1) => {
    return parseFloat((parseFloat(val) * qty * rateUnit).toFixed(10)).toFixed(
      5
    );
  },

  convertRateToUsd: (value, unit) => {
    return (parseFloat(value) * parseFloat(unit)).toFixed(5);
  },

  convertChainRate: (value, unit) => {
    if (unit !== 0) return (value / unit).toFixed(5);
  },
  ChainOptions: [
    //for Dropdown
    { value: 1, label: "Ethereum", chainIds: [1, 5] },
    { value: 2, label: "Binance", chainIds: [56, 97] },
    { value: 3, label: "Fantom", chainIds: [250, 4002] },
    { value: 4, label: "Cronos", chainIds: [25, 338] },
  ],
  config1: {
    price: {
      blockChain: "",
      currency: "",
      amount: "",
    },
    listingSchedule: {
      startDate: moment(),
      endDate: moment().add(6, "months"),
      startTime: moment(new Date()).format("HH:mm"),
      endTime: "23:59",
    },
    reserveFor: "", // if user selects reserve buyer
    quantity: 1,
  },
  config2: {
    method: 1, // 1 = Sell to the highest bidder or 2 = Sell with the declining price
    startPrice: {
      currency: "",
      amount: "",
      blockChain: "",
    },
    endPrice: {
      currency: "",
      amount: "",
      blockChain: "",
    },
    duration: {
      startDate: moment(),
      endDate: moment().add(6, "months"),
      startTime: moment(new Date()).format("HH:mm"),
      endTime: "23:59",
    },
    // if user includes reserve price
    reservePrice: {
      currency: "",
      amount: "",
      blockChain: "",
    },
  },
  config3: {
    method: 1, // 1 = Sell to the highest bidder or 2 = Sell with the declining price
    startPrice: {
      currency: "",
      amount: "",
      blockChain: "",
    },
    endPrice: {
      currency: "",
      amount: "",
      blockChain: "",
    },
    duration: {
      startDate: moment(),
      endDate: moment().add(6, "months"),
      startTime: moment(new Date()).format("HH:mm"),
      endTime: "23:59",
    },
    // if user includes reserve price
    reservePrice: {
      currency: "",
      amount: "",
      blockChain: "",
    },
  },
  tokenStandards: {
    TS1: "ERC-721",
    TS2: "ERC-1155",
  },
  formatAddress: (address) => {
    return address ? address.substr(0, 7) + "..." + address.substr(-4) : null;
  },
  copy: (address) => {
    navigator.clipboard.writeText(address);
    toast.success("Address Copied To Clipboard.");
  },
};
