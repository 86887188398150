import { BEFORE_HOME, SET_BANNER_NFT, SET_LOGIN_PAGE_NFT, SET_NOTABLE_COLLECTIONS, SET_TOP_COLLECTIONS, SET_MOST_SOLD, SET_TOP_CATEGORY, SET_STATS } from '../../redux/types';

const initialState = {
    bannerNftAuth: false,
    bannerNft: null,
    loginNftAuth: false,
    loginNft: null,
    notableCollectionAuth: false,
    notableCollection: null,
    topCollectionAuth: false,
    topCollection: null,
    mostSoldAuth: false,
    mostSold: null,
    topCategoriesAuth: false,
    topCategories: null,
    statsAuth: false,
    stats: null,
}

export default function homeRed(state = initialState, action) {
    switch (action.type) {
        case SET_BANNER_NFT:
            return {
                ...state,
                bannerNft: action.payload,
                bannerNftAuth: true,
            }
        case SET_LOGIN_PAGE_NFT:
            return {
                ...state,
                loginNftAuth: true,
                loginNft: action.payload
            }
        case SET_NOTABLE_COLLECTIONS:
            return {
                ...state,
                notableCollection: action.payload,
                notableCollectionAuth: true,
            }
        case SET_TOP_COLLECTIONS:
            return{
                ...state,
                topCollection: action.payload,
                topCollectionAuth: true,
            }
        case SET_MOST_SOLD:
            return{
                ...state,
                mostSold: action.payload,
                mostSoldAuth: true,
            }
        case SET_TOP_CATEGORY:
            return{
                ...state,
                topCategories: action.payload,
                topCategoriesAuth: true,
            }
        case SET_STATS:
            return{
                ...state,
                stats: action.payload,
                statsAuth: true,
            }
        case BEFORE_HOME:
            return {
                ...state,
                bannerNftAuth: false,
                bannerNft: null,
                loginNftAuth: false,
                loginNft: null,
                notableCollectionAuth: false,
                notableCollection: null,
                topCollectionAuth: false,
                topCollection: null,
                mostSoldAuth: false,
                mostSold: null,
                topCategoriesAuth: false,
                topCategories: null,
                statsAuth: false,
                stats: null,
            }
        default:
            return {
                ...state
            }
    }
}