import React, { useState, useEffect } from "react";
import Explore from "../explore/explore-all";
import FullPageLoader from "../../components/full-page-loader/full-page-loader";
import { connect } from "react-redux";
import {
  beforeCollection,
  getCollection,
} from "../collections/collections.actions";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import localeInfo from "rc-pagination/lib/locale/en_US";
import { Link, useParams, useNavigate } from "react-router-dom";
import { ENV } from "../../config/config";
import FilterSearch from "../filter-search/filtersearch";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { getChainId } from "../../utils/web3";
import { Tabs, Tab, Row, Col } from "react-bootstrap";
import AboutSidebar from "../about-sidebar/about-sidebar";
import AboutNfts from "../about-nfts/about-nfts";
import checked from "../../assets/images/tik.png";
import SmallChecked from "../../assets/images/tik.png";
import SearchFilters from "../search-filters/search-filters";
import ActivityTable from "../activity-table/activity-table";
import ActivityGraph from "./activity-graph";
import Select from "react-select";
import { useWindowSize } from "../../hooks/useWindowSize";
import Ethereum from "../../assets/images/arow-icon.png";
import Binance from "../../assets/images/binancelogo.png";
import Fantom from "../../assets/images/fantomlogo.png";
import Cronos from "../../assets/images/cronoslogo.png";
const authorPlaceholderImg = ENV.userDefaultImg;

let icons = {
  1: Ethereum,
  2: Binance,
  3: Cronos,
  4: Fantom,
};

const {
  userDefaultImg,
  categoryDefaultImg,
  globalPlaceholderImage,
  collectionFeaturedImg,
} = ENV;
const CollectionDetails = (props) => {
  const [collection, setCollection] = useState(null);
  const [blockChainId, setBlockChainId] = useState(null);
  const [stats, setStats] = useState(null);
  const [heighestSale, setHeighestSale] = useState(0.0);
  const [collectionLoader, setColLoader] = useState(true); // collections loader
  const [nftsLoader, setNftsLoader] = useState(true); // NFTs loader
  const [nftFilter, setNftFitler] = useState(null);
  const [Filter, setFilter] = useState(false);
  const { _id } = ENV.getUserKeys("_id");
  const [chainId, setChainId] = useState("");
  const [key, setKey] = useState("about");
  const params = useParams();
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(true);
  const { width } = useWindowSize() || {};
  const [selectedOption, setSelectedOption] = useState({
    value: "90",
    label: "90 Days",
  });
  const [graph, setGraph] = useState(null);
  const [filters, setFilters] = useState({
    creation: false,
    offers: false,
    bids: false,
    acceptOffer: false,
    acceptBid: false,
    listings: false,
    sales: false,
    cancelListing: false,
  });

  const [pagination, setPagination] = useState(null);

  const onPageChange = async (page) => {
    // setLoader(true);
    // const qs = ENV.objectToQueryString({ page });
    const { collectionId } = params;
    if (collectionId) {
      props.getCollection(collectionId, page);
    } else {
      navigate("/collections");
    }
  };

  useEffect(() => {
    if (width <= 991) {
      setShowFilter(false);
    } else {
      setShowFilter(true);
    }
  }, [width]);

  useEffect(() => {
    window.scroll(0, 0);
    const { collectionId } = params;
    if (collectionId) {
      props.getCollection(collectionId);
    } else {
      navigate("/collections");
    }
    getChainId().then(setChainId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // when collection data is received
  useEffect(() => {
    if (props.collection.getDetailAuth) {
      const { collection, pagination } = props.collection;
      setCollection(collection);
      setStats(collection.stats);
      setBlockChainId(collection.blockChain);
      console.log("pagination..................", pagination);
      setPagination(pagination);
      props.beforeCollection();
      setColLoader(false);
    }
  }, [props.collection.getDetailAuth]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // when an error is received
    if (props.error && props.error.invalidCol) navigate("/collections");
  }, [props.error && props.error.invalidCol]); // eslint-disable-line react-hooks/exhaustive-deps

  const getNftsLoader = (loader) => {
    setNftsLoader(loader);
  };

  const applyFilters = (filter) => {
    if (filter) {
      setNftsLoader(true);
      setNftFitler(filter);
    }
  };

  const copy = () => {
    var copyText = document.getElementById("author-add-field");
    navigator.clipboard.writeText(copyText.value);
    toast.success("Address Copied To Clipboard.");
  };

  // options For Activities

  const options = [
    { value: "90", label: "90 Days" },
    { value: "60", label: "60 Days" },
    { value: "30", label: "30 Days" },
    { value: "7", label: "7 Days" },
  ];

  useEffect(() => {
    if (props.app && stats != null) {
      if (
        stats?.maxSaleprice !== 0.0 &&
        stats?.maxSaleprice > 0.0 &&
        stats?.marketCap !== 0.0 &&
        props.app.ethRateAuth &&
        blockChainId === 1
      ) {
        let MSPconvertToUsd = ENV.convertRateToUsd(
          stats.maxSaleprice,
          props.app.ethRate
        );
        if (MSPconvertToUsd) {
          setHeighestSale(MSPconvertToUsd);
        }
      }
      if (
        stats?.maxSaleprice !== 0.0 &&
        stats?.maxSaleprice > 0.0 &&
        stats?.marketCap !== 0.0 &&
        props.app.rateAuth &&
        blockChainId === 2
      ) {
        let MSPconvertToUsd = ENV.convertRateToUsd(
          stats.maxSaleprice,
          props.app.rate
        );
        if (MSPconvertToUsd) {
          setHeighestSale(MSPconvertToUsd);
        }
      }
      if (
        stats?.maxSaleprice !== 0.0 &&
        stats?.maxSaleprice > 0.0 &&
        stats?.marketCap !== 0.0 &&
        props.app.fantomRateAuth &&
        blockChainId === 3
      ) {
        let MSPconvertToUsd = ENV.convertRateToUsd(
          stats.maxSaleprice,
          props.app.fantomRate
        );
        if (MSPconvertToUsd) {
          setHeighestSale(MSPconvertToUsd);
        }
      }
      if (
        stats?.maxSaleprice !== 0.0 &&
        stats?.maxSaleprice > 0.0 &&
        stats?.marketCap !== 0.0 &&
        props.app.wcroRateAuth &&
        blockChainId === 4
      ) {
        let MSPconvertToUsd = ENV.convertRateToUsd(
          stats.maxSaleprice,
          props.app.wcroRate
        );
        if (MSPconvertToUsd) {
          setHeighestSale(MSPconvertToUsd);
        }
      }
    }
  }, [props.collection.getDetailAuth, props.app]);

  return (
    <section
      className="author-area explore-area  popular-collections-area"
      style={{ padding: "0px" }}
    >
      {(collectionLoader || nftsLoader) && <FullPageLoader />}
      <div className="">
        {collection && (
          <div className="d-flex collection-detail-container">
            <div className="collection-preview collection-card">
              <div className="seller">
                <div className="seller-thumb avatar-lg-collection-details">
                  <img
                    className="collection-logo"
                    src={
                      collection.logo ? collection.logo : collectionFeaturedImg
                    }
                    alt="Collection Logo"
                  />
                </div>
              </div>
            </div>
            <div className="card-caption p-0 text-center">
              <div className="card-body pt-0 pb-0">
                <div className="about-banner-data d-flex justify-content-center align-items-center">
                  <h5>{collection.name}</h5>
                  {collection.isVerified && (
                    <span className="banner-checked-1 ml-1">
                      <img className="img-fluid" src={checked} />
                    </span>
                  )}
                </div>
                <div>
                  {_id && collection?.user?._id === _id && (
                    <>
                      <div className="mb-5">
                        {!collection?.isDefault && (
                          <button
                            className="gradient-button btn "
                            disabled={collection?.isDefault}
                            onClick={() =>
                              navigate(`/collection/edit/${collection?.url}`)
                            }
                            style={{ marginLeft: "20px" }}
                          >
                            Edit Collection
                          </button>
                        )}
                        {/* collection=${window.btoa(collection?._id)} */}
                        <button
                          className="gradient-button btn "
                          onClick={() => navigate(`/choose-blockchain`)}
                          style={{ marginLeft: "20px" }}
                        >
                          Create NFT
                        </button>
                        {/* <button className='gradient-button btn ' onClick={() => navigate(`/collection/${collection?.url}/payouts`)} style={{ 'marginLeft': '20px' }}>
                          Earnings
                        </button> */}
                      </div>
                    </>
                  )}
                </div>

                <div className="about-data-two d-flex justify-content-center align-items-center">
                  {/* <span className="purple-span">{collection?.isDefault ? "@elumnt" : collection.user?.username}</span> */}
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control cursor-pointer"
                      id="author-add-field"
                      data-effect="float"
                      data-tip=""
                      placeholder="0x000000000000000000"
                      readOnly
                      value={ENV.formatAddress(
                        collection?.address
                          ? collection?.address
                          : "0x000000000000000000"
                      )}
                    />
                    <span className="ethirem-icon">
                      <img
                        className="img-fluid"
                        src={icons[Number(collection.blockChain)]}
                      />
                    </span>
                    <div
                      className="input-group-append"
                      style={{
                        position: "absolute",
                        height: "100%",
                        top: "0",
                        right: "0",
                        borderRadius: "100px",
                        overflow: "hidden",
                      }}
                      onClick={() => ENV.copy(collection?.address)}
                    >
                      <button
                        style={{
                          marginRight: "5px",
                          padding: "12px 16px",
                          backgroundColor: "transparent",
                          color: "#fff",
                          border: "0",
                          outline: "none",
                        }}
                      >
                        <i
                          className="icon-docs"
                          data-effect="float"
                          data-tip="Copy"
                        />
                      </button>
                    </div>
                  </div>
                  <span className="authorname d-flex justify-content-center align-items-center">
                    <span>by</span>
                    <span className="author-img">
                      <img className="img-fluid" src={authorPlaceholderImg} />
                    </span>
                    <span className="author-name text-white">
                      {collection?.isDefault ? (
                        "METAMUTO"
                      ) : (
                        <Link to={`/artist/${collection?.user?.username}`}>
                          {collection.user.username}
                        </Link>
                      )}
                    </span>
                    <span className="author-check">
                      <img className="img-fluid" src={SmallChecked} />
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className="items four-box-column">
              <div className="flex-fill">
                <div className="card no-hover">
                  <div className="single-seller">
                    {/* <Link to="/authors">
                      <div className="avatar-md rounded-circle" data-effect="float" data-tip="Owners">
                        <i className="fas fa-users" />
                      </div>
                    </Link> */}
                    <div className="seller-info text-center">
                      <span>Market Cap</span>
                      {/* <Link className="seller" to="#" data-effect="float" data-tip={marketCap || 0.0}> */}
                      <div
                        className="seller d-flex justify-content-center align-items-center"
                        data-effect="float"
                        data-tip={stats?.marketCap || 0}
                      >
                        <span className="mr-2 mt-1">
                          <img
                            src={icons[Number(collection.blockChain)]}
                            className="img-fluid"
                          />
                        </span>
                        {stats?.marketCap || 0}
                      </div>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-fill">
                <div className="card no-hover">
                  <div className="single-seller">
                    {/* <img className="cursor-pointer" src={BNBLogo} alt="BNB Logo" width={64} height={64} data-effect="float" data-tip="BNB" /> */}
                    <div className="seller-info text-center">
                      <span>Floor Price</span>
                      <div
                        className="seller d-flex justify-content-center align-items-center"
                        data-effect="float"
                        data-tip={stats?.floorPrice || 0}
                      >
                        <span className="mr-2 mt-1">
                          <img
                            src={icons[Number(collection.blockChain)]}
                            className="img-fluid"
                          />
                        </span>
                        {stats?.floorPrice || 0}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-fill">
                <div className="card no-hover">
                  <div className="single-seller">
                    <div className="seller-info text-center">
                      <span>Highest Sale</span>
                      <div
                        className="seller"
                        data-effect="float"
                        data-tip={heighestSale}
                      >
                        ${heighestSale}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-fill">
                <div className="card no-hover">
                  <div className="single-seller">
                    <div className="seller-info text-center">
                      <span>Items</span>
                      <div
                        className="seller"
                        data-effect="float"
                        data-tip={collection.items || 0}
                      >
                        {collection.items || 0}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-fill">
                <div className="card no-hover">
                  <div className="single-seller">
                    <div className="seller-info text-center">
                      <span>Owners</span>
                      <Link
                        className="seller"
                        to="#"
                        data-effect="float"
                        data-tip={collection.owners || 0}
                      >
                        {collection.owners || 0}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex-fill">
                <div className="card no-hover">
                  <div className="single-seller">
                    <div className="seller-info text-center">
                      <span>Volume Traded</span>
                      <div
                        className="seller d-flex justify-content-center align-items-center"
                        data-effect="float"
                        data-tip={stats?.volumeTraded || 0}
                      >
                        <span className="mr-2 mt-1">
                          <img
                            src={icons[Number(collection.blockChain)]}
                            className="img-fluid"
                          />
                        </span>
                        {stats?.volumeTraded || 0}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ReactTooltip />
            <div className="collection-tabs custom-container w-100">
              <Tabs
                id="controlled-tab"
                activeKey={key}
                onSelect={(k) => {
                  //setLoaders on Tab Switch
                  setKey(k);
                  if (k == "about") getNftsLoader(true);
                  if (k == "activity") setColLoader(true);
                  //for third tab items its loader present in its own component
                }}
                className="mb-3"
              >
                <Tab eventKey="about" title="About">
                  <Row>
                    <Col md={12} className="about-toggle">
                      <SearchFilters
                        setShowFilter={setShowFilter}
                        showFilter={showFilter}
                        filters={false}
                      />
                    </Col>
                    <Col xl={3} lg={4}>
                      {showFilter && <AboutSidebar collection={collection} />}
                    </Col>
                    <Col xl={9} lg={8}>
                      {key === "about" && collection?._id && (
                        <AboutNfts
                          setKey={setKey}
                          keyValue="items"
                          setLoader={getNftsLoader}
                          collectionId={collection._id}
                        />
                      )}
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="items" title="Items">
                  <Row>
                    <Col md={12}>
                      {key === "items" && (
                        <Explore collectionId={collection?._id} />
                      )}
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="activity" title="Activity">
                  <Row>
                    <Col md={12}>
                      <SearchFilters
                        setShowFilter={setShowFilter}
                        showFilter={showFilter}
                        filters={false}
                      />
                    </Col>
                    <Col md={12} className="d-flex">
                      {showFilter && (
                        <div className="items-sidebar">
                          <FilterSearch
                            applyFilters={applyFilters}
                            items={false}
                            activity={true}
                            filters={filters}
                            setFilters={setFilters}
                          />
                        </div>
                      )}
                      {key === "activity" && (
                        <div
                          className={`items-nfts ${
                            showFilter ? "" : "no-sidebar"
                          } `}
                        >
                          <div className="chart-select">
                            <Select
                              className="custom-activity-select"
                              defaultValue={selectedOption}
                              onChange={setSelectedOption}
                              options={options}
                              classNamePrefix="elumnt-select-create-nft"
                            />
                          </div>
                          {<ActivityGraph graph={graph} />}
                          {collection._id && (
                            <div>
                              <span>
                                {pagination &&
                                  pagination.limit * pagination.page -
                                    pagination.limit +
                                    1}
                              </span>

                              {pagination && (
                                <Pagination
                                  className="m-3"
                                  defaultCurrent={1}
                                  pageSize // items per page
                                  current={pagination.page} // current active page
                                  total={pagination.pages} // total pages
                                  onChange={onPageChange}
                                  locale={localeInfo}
                                />
                              )}
                              <ActivityTable
                                setLoader={setColLoader}
                                selectedOption={selectedOption}
                                collectionId={collection._id}
                                setGraph={setGraph}
                                filters={filters}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  error: state.error,
  collection: state.collection,
  app: state.app,
});

export default connect(mapStateToProps, { beforeCollection, getCollection })(
  CollectionDetails
);
