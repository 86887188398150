import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import topImageOne from "../../../assets/images/100.png";
import "../top-card/top-card.css";
import { ENV } from '../../../config/config';
const itemPlaceholderImg = ENV.collectionFeaturedImg

const TopCard = (props) => {
    const [topMediaContent, setTopMediaContent] = useState([])

    useEffect(() => {
        if(props.stats){
            setTopMediaContent(props.stats)
        }else{
            setTopMediaContent([])
        }
    }, [props.stats])

    const convertToUsd = (price, currency) => {
        if (props.app) {
          if (props.app.ethRateAuth && currency === 'ETH') {
            let MCconvertToUsd = ENV.convertRateToUsd(price, props.app.ethRate)
            return ENV.exponentialToDecimal(MCconvertToUsd)
          }
          if (props.app.rateAuth && currency === 'BNB') {
            let MCconvertToUsd = ENV.convertRateToUsd(price, props.app.rate)
            return ENV.exponentialToDecimal(MCconvertToUsd)

          }
          if (props.app.fantomRateAuth && currency === 'FTM') {
            let MCconvertToUsd = ENV.convertRateToUsd(price, props.app.fantomRate)
            return ENV.exponentialToDecimal(MCconvertToUsd)

          }
          if (props.app.wcroRateAuth && currency === 'CRO') {
            let MCconvertToUsd = ENV.convertRateToUsd(price, props.app.wcroRate)
            return ENV.exponentialToDecimal(MCconvertToUsd)
          }
          else{
            return 0;
          }
        }
        else{
            return 0;
          }
    }
    
    return (
        <> 
            {topMediaContent && topMediaContent.length ?
                <div className="top my-5 row ">
                    <Container fluid >
                        <Row className="top-collection-row">
                            {topMediaContent.map((i, index) =>
                                <Col className="top-collection-column" key={index} lg={4} md={6} >
                                    <div className="top-card">
                                        <div className="media">
                                        <span className="top-card-counter">{index+1}</span>
                                            <Link to={`/collection/${i.collection?.url}`}>
                                                <img src={i.collection.logo ? i.collection.logo : itemPlaceholderImg} className="media-object" />
                                            </Link>
                                            <div className="media-body ps-4">
                                                <div className="d-flex mb-2">
                                                    <h3 className="media-heading flex-grow-1">{i.collection.name}</h3>
                                                    <span className="eth-value">{ENV.exponentialToDecimal(i.totalValue).toFixed(2)} {i.currency}</span>
                                                </div>
                                                <div className="d-flex">
                                                    <h3 className=" floor flex-grow-1">Floor: {parseFloat(i.floorPrice).toFixed(2)} {i.currency}</h3>
                                                    <span className="object-value-1">${convertToUsd(i?.totalValue, i?.currency).toFixed(2)} {props.filter?.sortByVol === 'v24h' ? <span className={`object-value-2${i?.p24h?.charAt(0) === "-" ? '-red' : ''}`}>{i?.p24h}</span> : props.filter?.sortByVol === 'v7d' ? <span className={`object-value-2${i?.p7d?.charAt(0) === "-" ? '-red' : ''}`}>{i?.p7d}</span> : props.filter?.sortByVol === 'v30d' ? <span className={`object-value-2${i?.p30d?.charAt(0) === "-" ? '-red' : ''}`}>{i?.p30d}</span> : <span className="object-value-2">0%</span>}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </Container>
                </div>
                :
                <div className="no-data border "><p className="text-center">No Record Found</p></div>
            }
        </>
    );
}

const mapStateToProps = (state) => ({
    error: state.error,
    app: state.app
  });
  
  export default connect(mapStateToProps)(TopCard);