import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import './chat.css';

const Welcome = (props) => {

  const navigate = useNavigate();

  const handleLoginButton = () => {
    navigate("/login")
  }
  
  return (
    <>
      {props.currentUser === undefined ? (
        <section className="welcome-container">
          <h1>
            Welcome,
          </h1>
          <h3>Please Login to view your chats.</h3>
          <button type="button" className='login-btn' onClick={() => handleLoginButton()}>
            LOGIN
          </button>
        </section>
        ) : (
        <section className="welcome-container">
          <h1>
            Welcome, <span>{props.currentUser.username}!</span>
          </h1>
          <h3>Please select a chat to Start messaging.</h3>
        </section>
      )}
    </>
  );
}

export default Welcome