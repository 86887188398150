import React, { useState, useEffect, useRef } from "react";
import ChatInput from "./ChatInput";
import { v4 as uuidv4 } from "uuid";
import { ENV } from '../../config/config';
import axios from "axios";
import './chat.css';

export default function ChatContainer({currentChat, socket, updateNotification, updateMessage}) {

  const [messages, setMessages] = useState([]);
  const scrollRef = useRef(null);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [check, setCheck] = useState(false);
  
  useEffect(() => {
    setCheck(true)
    const authId = ENV.getUserKeys()?._id;
    // const data = JSON.parse(
    //   localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
    // );
    fetchData(authId)
      .then((res) => {
        setMessages(res.data.data.projectedMessages);
        setCheck(false)
      })
      .catch((e) => {
    })

  }, [currentChat]);

  const fetchData = async (authId) => {

return await axios.get(`${process.env.REACT_APP_BASE_URL}/chat/get-message/?messageFrom=`+authId+`&userId=`+currentChat._id, {
      headers: {
        'content-type': 'application/json',
        'Authorization': ENV.Authorization,
        'x-auth-token': ENV.x_auth_token,
        'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
      }
    });
  }

  useEffect(() => {

    const getCurrentChat = async () => {
      if (currentChat) {
        await JSON.parse(
          localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
        )._id;
      }
    };
    getCurrentChat();
  }, [currentChat]);

  const handleSendMsg = async (msg) => {
    const data = await JSON.parse(
      localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
    );
    socket.current.emit("send-msg", {
      to: currentChat._id,
      from: data._id,
      msg,
    });

    await axios.post(`${process.env.REACT_APP_BASE_URL}/chat/send-message`, 
      {
        messageFrom: data._id,
        messageTo: currentChat._id,
        message: msg,
      },
      {
        headers: {
          'content-type': 'application/json',
          'Authorization': ENV.Authorization,
          'x-auth-token': ENV.x_auth_token,
          'x-access-token': ENV.getUserKeys('accessToken') && ENV.getUserKeys('accessToken').accessToken ? ENV.getUserKeys('accessToken').accessToken : ''
        }
      }
    );

    const msgs = [...messages];
    msgs.push({ fromSelf: true, message: msg });
    setMessages(msgs);
  };

  useEffect(() => {
    if (socket.current) {
      socket.current.on("msg-recieve", (data) => {
        data.date = new Date()
        if(data.from === currentChat._id){
          setArrivalMessage({ fromSelf: false, message: data.msg });
          updateMessage(data, true)
        }else{
          updateMessage(data, true)
          updateNotification(data, true)
        }
      });
    }
  }, [currentChat]);

  useEffect(() => {
    arrivalMessage && setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage]);

  useEffect(() => {
    if(scrollRef.current)
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

  return (
    <div className="ChatContainer-container">
      <div className="chat-messages">
        {messages.map((message) => {
          return (
            <div id="scroll-view" ref={scrollRef} key={uuidv4()}>
              <div
                className={`message ${
                  message.fromSelf ? "sended" : "recieved"
                }`}
              >
                <div className="content ">
                  <p>{message.message}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <ChatInput empty={check} handleSendMsg={handleSendMsg} />
    </div>
  );
}