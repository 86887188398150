import React from 'react';
import ItemDetail from '../item-details/item-details';

function ItemDetails(props) {
     return (
        <>
            <ItemDetail history={props.history} params={props.match?.params} />
        </>
    );
}

export default ItemDetails;