import React from 'react'
import { Container, Row } from "react-bootstrap";
import CollectionCards from '../collection-card/CollectionCards';
import CustomHeading from '../custom-heading/CustomHeading';
import '../noteable-collections/noteable-collection.css'
function NoterableCollection() {
  return (
    <>
      <section className="Statistics">
        <Container fluid className="custom-container">
          {/* <Row> */}
            <CustomHeading heading="Notable" collection="Collections" />
            <CollectionCards />
          {/* </Row> */}
        </Container>
      </section>


    </>
  )
}

export default NoterableCollection