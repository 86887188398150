import { BEFORE_ATTRIBUTES, GET_ATTRIBUTES } from '../../redux/types';

const initialState = {
    attributes: null,
    getAuth: false,
}

export default function attributesRed(state = initialState, action) {
    switch (action.type) {
        case GET_ATTRIBUTES:
            return {
                ...state,
                attributes: action.payload,
                getAuth: true
            }
        case BEFORE_ATTRIBUTES:
            return {
                ...state,
                attributes: null,
                getAuth: false,
            }
        default:
            return {
                ...state
            }
    }
}