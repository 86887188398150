import { useEffect } from "react";

const TermCondition = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <section className="explore-area privacy-and-terms pt-3">
      <div className="custom-container">
        <div class="intro">
          <div class="intro-content flex-fill text-center">
            <h3 class="my-3">Terms and Conditions</h3>
          </div>
        </div>
        <div className="p-3 pt-5">
          <span className="mt-0 mb-1">
            Last Updated: <span className="privacy-date">January 18, 2023</span>
          </span>
        </div>
        <div className="px-md-3 px-0 py-2 pt-3">
          <p>
            This end-user pact (the "Agreement," "Terms of Use") should be read and accepted by the "User" in its entirety before he starts using Metamuto and its services. Furthermore, be aware that this Agreement constitutes a legally binding agreement between the User and Metamuto (referred to herein as "Metamuto," "us," or "we"), which owns, operates, and maintains this platform “https://Metamuto.metamuto.com” (website and all its modules and functionalities together referred to as "Service.")
          </p>
        </div>
        <div className="px-md-3 px-0 py-2">
          <p>
            As soon as you access and start using our Service, we consider it your formal consent for this Agreement, believing that you have read and understood the Terms of Use and agree to be bound by them.
          </p>
        </div>
        <div className="px-md-3 px-0 pb-2 pt-1">
          <h5>Grant of License:</h5>
          <ul>
            <li>Metamuto officially allows the User a personal, non-exclusive, and non-transferable right to use the Service on his personal computer or other devices that access the Internet to access and use the services described on the Metamuto), subject to the Terms of Use contained herein</li>
          </ul>
        </div>
        <div className="px-md-3 px-0 pb-2 pt-1">
          <ul>
            <li>The Service is not for use by:</li>
          </ul>
        </div>
        <div className="py-2 px-5 response-paragraph ">
          <ul>
            <li>Individuals under the age of 13 years.</li>
            <li>Minors and individuals under the age of majority adulthood in their specific jurisdiction(s).</li>
            <li>Individuals who access and use the Service from any jurisdiction in which it is illegal to do so.</li>
          </ul>
          <p>Metamuto cannot verify the legality of the Service in every jurisdiction. Therefore, it is entirely up to the User to determine whether or not their use of the Service is lawful. </p>
        </div>
        <div className="px-md-3 px-0 pb-2 pt-1">
          <ul>
            <li>Metamuto and its licensors are the sole holders of all rights in and to the Service and code, technology, organization, structure, and architecture, including copyright, trade secrets, intellectual property, and other rights. You may not: </li>
          </ul>
        </div>
        <div className="py-2 px-5 response-paragraph ">
          <ul>
            <li>Copy, distribute, publish, create derivative works, reverse engineer, modify, decompile, disassemble, or translate the Metamuto website or the Service.</li>
            <li>Use the Service if prohibited (for any reason) by applicable laws in any jurisdiction. </li>
          </ul>
          <p>The user may face the consequences of the Unauthorized Use of this Service, and Metamuto will not take any responsibility in such a case. </p>
        </div>
        <div className="px-md-3 px-0 pb-2 pt-1">
          <ul>
            <li>Metamuto reserves all implied or otherwise rights not expressly granted to the User hereunder and retains all rights and interests in the Service. The User will be solely liable for any damage or cost arising from (or associated with) any Unauthorized Use of the Service. You shall notify Metamuto immediately upon becoming aware of the commission by any person of any Unauthorized Use. You shall also provide Metamuto with reasonable assistance with any investigations it conducts in light of your provided information in this respect.</li>
          </ul>
        </div>
        <div className="px-md-3 px-0 pb-2 pt-1">
          <ul>
            <li>The term "Metamuto," its domain names, and any other trademarks or service marks used by https://Metamuto.metamuto.com are solely owned by Metamuto. Moreover, all content (textual and visual) on Metamuto, such as text, images, animations, videos, audio, etc., belongs to Metamuto. All of the website content and other assets related to the Service are copyright protected under the intellectual property or other rights. </li>
          </ul>
        </div>
        <div className="px-md-3 px-0 pb-2 pt-1">
          <ul>
            <li>The User hereby must acknowledge that by using the Service, he obtains no rights in the Website Content, Trademarks, and/or associated Service Marks or any part of it. The User, under no circumstances, may use the Website Content, Trademarks, and/or associated Service Marks without the written consent of Metamuto. In addition, the User agrees to prevent doing anything that could potentially harm Metamuto's intellectual property rights and any part thereof. </li>
          </ul>
        </div>

        <div className="px-md-3 px-0 py-2 pt-3">
          <h5>Restrictions:</h5>
          <p>You shall not use the Service except as expressly permitted in these Terms.  You shall not.</p>
        </div>
        <div className="py-3 px-5 response-paragraph ">
          <ul>
            <li>Involve in theft, fraud, and other malicious activities when using Metamuto. Any attempts to use our Service for illegal or dishonest purposes will not be tolerated. If we suspect that you are misusing Metamuto in any way, we may suspend or terminate your account immediately.</li>
          </ul>
        </div>
        <div className="pb-2 pt-1 px-5 response-paragraph ">
          <ul>
            <li>Violate any of the applicable laws and regulations in any way.</li>
          </ul>
        </div>
        <div className="pb-2 pt-1 px-5 response-paragraph ">
          <ul>
            <li>Infringe any proprietary rights, including but not limited to copyrights, patents, trademarks, or trade secrets of Metamuto.</li>
          </ul>
        </div>
        <div className="pb-2 pt-1 px-5 response-paragraph ">
          <ul>
            <li>Use the Service to transmit any data or material containing malware, spyware, keystroke loggers, Trojan horses, etc., of any harmful computer code designed to affect Metamuto's operations adversely.</li>
          </ul>
        </div>
        <div className="pb-2 pt-1 px-5 response-paragraph ">
          <ul>
            <li>Use any bots, web crawlers, deep linking, spiders, scripts, algorithms, automatic devices, etc., or any manual process equivalent to these automated processes to access, copy, replicate, bypass, or spy the Website or the Service.</li>
          </ul>
        </div>
        <div className="pb-2 pt-1 px-5 response-paragraph ">
          <ul>
            <li>Make archival copies or backups of the Website and the Service-related assets or any part of it; for example, de-compilation or disassembling the Site.</li>
          </ul>
        </div>
        <div className="pb-2 pt-1 px-5 response-paragraph ">
          <ul>
            <li>Violate public morals and interests or any legitimate interests and preferences of other Users using the Service. This may include but is not limited to the activities that disrupt, interfere with, prohibit, or negatively affect other Users. </li>
          </ul>
        </div>
        <div className="pb-2 pt-1 px-5 response-paragraph ">
          <ul>
            <li>Use the Site and the Service for market manipulation. This may include but is not limited to wash trading, front running, spoofing, self-trading, etc., regardless of whether prohibited by the law. </li>
          </ul>
        </div>
        <div className="pb-2 pt-1 px-5 response-paragraph ">
          <ul>
            <li>Attempt to access and use the Service or any part or function of the Website without the authorization of Metamuto or tend to connect to any other system/networks associated with our Service by password mining, cyber hacking, or any other illegitimate or prohibited means.</li>
          </ul>
        </div>
        <div className="pb-2 pt-1 px-5 response-paragraph ">
          <ul>
            <li>Probe, scan or test the Metamuto's vulnerabilities or any network or system connected to the Service properties or violate any authentication or security measures on the Website or any network/system connected thereto.</li>
          </ul>
        </div>
        <div className="pb-2 pt-1 px-5 response-paragraph ">
          <ul>
            <li>Track, reverse look-up, or seek to track any data of any other User or visitor of Metamuto.</li>
          </ul>
        </div>
        <div className="pb-2 pt-1 px-5 response-paragraph ">
          <ul>
            <li>Use any software, device, or routine programs to interfere and impede Metamuto's typical operation of any transaction or any other individual's use of the Service.</li>
          </ul>
        </div>
        <div className="px-md-3 px-0 py-2 pt-3">
           <p>By accessing and using Metamuto, the User agrees to the right of the Service provider to investigate any violation of the abovementioned Terms and Conditions. The Service provider can unilaterally determine whether the User has violated these clauses and take all legitimate actions under relevant laws and regulations without the User's consent or prior notice.</p>
        </div>
      </div>
    </section>
  );
};

export default TermCondition;