import React, { useEffect } from "react";
import Header from "../../components/home/header/Header";
import { useWeb3React } from "@web3-react/core";
import connectors from '../../components/wallet/connector';
import Web3 from "web3";



const Layout5 = (props) => {
    const { account, activate, library } = useWeb3React();
    useEffect(() => {
        const provider = window.localStorage.getItem("provider");
        if (provider) {
            const connector = connectors[provider].provider;
            activate(connector, undefined, true)
                .then(async (res) => {
                    const web3Provider = await connector.getProvider();
                    const web3 = new Web3(web3Provider);
                    window.walletPO = web3
                })
                .catch((error) => {
                    //error
                });
        }
    }, [])
    useEffect(() => {
        if (account) {
            localStorage.setItem('connectedAddress', account);
        }
    }, [account])

    useEffect(() => {
        if (library) {
            window.library = library;
        }
    }, [library])
    return (
        <>
            <div>
                <div className="main">
                    {props.children}
                </div>
            </div>
        </>
    );
}

export default Layout5;

