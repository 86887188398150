import React from 'react'
import Banner from '../home/banner/Banner'
import Footer from '../home/footer/Footer'
import HowItWorks from '../home/how-it-works/HowItWorks'
import LearnMore from '../home/learn-more/LearnMore'
// import Navigation from '../home/navbar/Navbar'
import NoterableCollection from '../home/noteable-collections/NoterableCollection'
import PopularCategory from '../home/popular-categories/PopularCategory'
import ResourcesCards from '../home/resources/Resources'
import Statistics from '../home/statistics/Statistics'
import TopCollections from '../home/top-collections/TopCollections'
import TrendingNft from '../home/trending-nfts/TrendingNft'
// import Footer from '../home/home/footer/Footer';

function Template(props) {
	return (
		<>
			{/* <Navigation /> */}
			<Banner/>
			<Statistics />
			<NoterableCollection />
			<TopCollections />
			<TrendingNft />
			<HowItWorks />
			<ResourcesCards />
			<PopularCategory />
			<LearnMore />
			<Footer />
		</>
	);
}

export default Template;