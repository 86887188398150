import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';

import '../custom-button/custom-button.css'

const CustomButton = (props) => {
  const [pagination, setPagination] = useState({})

  useEffect(() => {
    if(props.pagination){
      setPagination(props.pagination)
    }
  }, [props.pagination])
  

  const navigate = useNavigate();
  return (
    <>
      {pagination.total >= 20 &&
        <div className="view-all-collection d-flex justify-content-center">
          <button className="btn btn-outline-danger" onClick={()=>navigate('/collections')}>
            <span className="outer d-flex justify-content-center">
                <span className="inner">
                  View all collections
                </span>
            </span>
          </button>
        </div>
      }
    </>
  )
}

export default CustomButton