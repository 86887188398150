import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import collectionOne from "../../../assets/images/resourceelumnt1.jpg";
import collectionTwo from "../../../assets/images/resourceelumnt2.jpg";
import collectionThree from "../../../assets/images/resourceelumnt3.jpg";
import collectionFour from "../../../assets/images/resourceelumnt4.jpg";
import CustomHeadingOne from "../custom-heading-one/CustomHeadingOne";
import "../resources/resources.css";

function ResourcesCards() {
  const resource = [
    { id: 1, image: collectionOne },
    { id: 2, image: collectionTwo },
    { id: 3, image: collectionThree },
    { id: 4, image: collectionFour },
  ];
  return (
    <>
    <CustomHeadingOne className="resource-heading" heading="Resources for you"/>
      <section className="Statistics">
        <Container fluid  className="custom-container">
          <Row>
            {resource.map((i, index) => (
              <Col key={index} xl={3} lg={4} sm={6}>
                <div className="resources-images position-relative">
                   <div className='fade-overlay'></div>
                  <img src={i.image} className="card-img img-fluid" alt="card" />
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default ResourcesCards;
