import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import exchangeImage from '../../../assets/images/exchangeee.png'
import femaleImage from '../../../assets/images/femaleee.png'
import nftImage from '../../../assets/images/buy-nftt.png'
import { useDispatch, useSelector } from 'react-redux'
import { subscribe, beforeSettings } from "../../home/footer/footer.action";
import landingImageOne from '../../../assets/images/landingpageiconbg1.png'
import landingImageTwo from '../../../assets/images/landingpageiconleftbg2.png'

const GetStarted = () => {

  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("");
  const [isSubcribed, setIsSubscribed] = useState(false);

  const dispatch = useDispatch();
  const settings = useSelector(state => state.settings);

  // const subscribe = () => {
  //   if (email !== ""
  //     // && emailValidate()
  //   )
  //     dispatch(subscribe({ email: email }))
  // }

  const submitHandler = (e) => {
    e.preventDefault();

    // if (emailValidate()) {
    if (email) {
      let qs = { email: email }
      dispatch(subscribe(qs))
    }
    // }
    // subscribe()
  }

  const handleOnChange = (event) => {
    setEmail(event.target.value)
  };

  useEffect(() => {
    console.log("settings subscribe auth", settings.subscribeAuth)
    if (settings.subscribeAuth)
      if (settings.subscribe) {
        setIsSubscribed(true)



        fetch('/pdfs/howtonftartist.pdf').then(response => {
          response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'howtonftartist.pdf';
            alink.click();
          })
        })



      }

    setEmail("")
    beforeSettings()
  }, [settings.subscribeAuth])

  const emailValidate = () => {
    let input = email ? email : '';
    let message = "";
    let isValid = true;

    if (typeof input !== "undefined") {

      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(input)) {
        isValid = false;
        message = "Please enter valid email address.";
      }
    }

    setEmailError(message);

    return isValid;
  }



  return (
    <>
      <section className='get-started'>
        <div className='landing-image-one'><img src={landingImageOne} className='img-fluid' alt='image' /></div>
        <div className='exhange-image'><img src={exchangeImage} className='img-fluid' alt='image' /></div>
        <div className='landing-image-two'><img src={landingImageTwo} className='img-fluid' alt='image' /></div>
        <div className='female-image'><img src={femaleImage} className='img-fluid' alt='image' /></div>
        <div className='nftt-image'><img src={nftImage} className='img-fluid' alt='image' /></div>
        <Container fluid className="custom-container">
          <Row>
            <Col md={12}>
              <div className='get-started-content'>
                <h2>How do I get started into NFTs as an Artist?</h2>
                <p>Subscribe to our newsletter and recieve our exclusive step-by-step guide to becoming an NFT artist today!</p>
                <form onSubmit={(e) => submitHandler(e)}>
                  <Form.Group className="mb-4">
                    <Form.Control type="email" required onChange={handleOnChange} value={email} placeholder="Enter your email" />
                  </Form.Group>
                  {isSubcribed ? <div><button className='btn btn-outline-primary learn-more-btn mb-3'>Subscribed!</button>
                    <div className='step-by-step-para'><p>Awesome, check your downloads as you have just now received your step-by-step guide to becoming an NFT artist!</p></div>
                  </div>
                    : <button className='btn  guide-btn mb-4' type="submit">Receive Your Guide</button>}
                </form>
              </div>
            </Col>
          </Row>

        </Container>
      </section>
    </>
  )
}

export default GetStarted