import React from 'react';

function NotFound() {
    return (
        <div className='d-flex flex-column justify-content-center align-items-center my-5 error-page'>
            <h1>404 - Page not found</h1>
            <p> This page is lost.</p>
            <p> We've explored deep and wide, but we can't find the page you were looking for.</p>
        </div>
    );
}
export default NotFound;