import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from './components/scroll-to-top/scroll-to-top'
import { Provider } from 'react-redux';
import store from './store';
import { toast, ToastContainer, Bounce } from 'react-toastify';
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core';
import getLibrary from './utils/getLibrary';

import 'react-toastify/dist/ReactToastify.css'

const NetworkContextName = "NETWORK";
const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <Web3ProviderNetwork getLibrary={getLibrary}>
      <Provider store={store}>
        <ToastContainer
          position="top-right"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          containerId={'elumnt-admin'}
          transition={Bounce}
        />
        <BrowserRouter>
          {/* <ScrollToTop> */}
          <App />
          
         
          {/* </ScrollToTop> */}
        </BrowserRouter>
      </Provider>
    </Web3ProviderNetwork>
  </Web3ReactProvider>
);

serviceWorker.unregister();
