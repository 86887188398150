import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col, Navbar, Dropdown, ListGroup, Modal, Form } from 'react-bootstrap';
import { Nav, Button } from "react-bootstrap";
import FullPageLoader from '../full-page-loader/full-page-loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faCopy, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons'
import { useWeb3React } from '@web3-react/core';
import { ENV } from '../../config/config'
import InfiniteScroll from 'react-infinite-scroll-component';
import { getNotifications, updateNotiCount, beforeUser } from './../user/user.action';
import MiniLoader from '../full-page-loader/mini-loader/MiniLoader';
import moment from "moment";

const Notifications = (props) => {

    const [loader, setLoader] = useState(false)
    const [loadMoreBtn, setLoadMoreBtn] = useState(false)
    const [pagination, setPagination] = useState(null)
    const [notifications, setNotifications] = useState([])
    const [moreCheck, setMoreCheck] = useState(true)

    useEffect(() => {
        if (props.status) {
            setLoader(true)
            getNotifications()
        }
    }, [props.status]) // eslint-disable-line react-hooks/exhaustive-deps

    // set notifications & pagination
    useEffect(() => {
        if (props.notifications) {
            setNotifications(props.notifications)
            setPagination(props.pagination)
            setLoader(false)
            setMoreCheck(false)
        }
    }, [props.notifications, props.status, moreCheck]) // eslint-disable-line react-hooks/exhaustive-deps

    // handle show load more button state when pagination is set
    useEffect(() => {
        if (pagination)
            setLoadMoreBtn((notifications && pagination.total > 0 && pagination.total > notifications.length && notifications.length > 0))
    }, [pagination]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (notifications && notifications.lenght > 0 && props.status)
            props.updateNotiCount()
    }, [notifications])

    const loadMore = () => {

        setLoader(true)
        setMoreCheck(true)

        const { page } = pagination

        // get more Notifications
        getNotifications(page + 1)
    }

    const getNotifications = (page = 1, limit = 12) => {
        let creatorQS = { page, limit }
        const qs = ENV.objectToQueryString(creatorQS)
        props.getNotifications(qs)
    }
    const handleCloseModal = () => {
        setLoader(false)
        props.beforeUser()
        props.closeModal()
    }

    return (
        <Modal show={props.status} onHide={handleCloseModal} className="menu-modal noti-modal">
            <span className="modal-cross-icon"><FontAwesomeIcon icon={faXmark} className="cross-icon-in" onClick={handleCloseModal} /></span>
            <Modal.Body id="scrollableDiv">
                {/* <div className="username d-flex align-items-center justify-content-between">
                        <span className="noti-tag">Notifications</span>
                        <span className="noti-tag">See all</span>
                    </div> */}
                {loader ?
                    <MiniLoader />
                    :
                    <div className="notifications-data">
                        <InfiniteScroll
                            dataLength={notifications?.length} //This is important field to render the next data
                            next={loadMore}
                            hasMore={loadMoreBtn}
                            loader={<h4>Loading...</h4>}
                            scrollableTarget="scrollableDiv"
                        >
                            {notifications && notifications?.length ?
                                notifications.map((notification, key) => {
                                    return (
                                        <div className='d-flex' key={`ato_${key}`}>
                                            <div className='image'>
                                                <Link to={`/item-details/${window.btoa(notification.nft[0]?._id)}`}>
                                                    <img className='' src={notification.nft[0]?.image} alt='image' />
                                                </Link>
                                            </div>

                                            <div className='content'>
                                                <Link to={`/item-details/${window.btoa(notification.nft[0]?._id)}`}>
                                                    <h2>{notification.nft[0]?.name}</h2>
                                                </Link>

                                                <div>
                                                    {notification.type === 1 && <h6 className='status'><Link to={`/artist/${notification.user[0]?.username}`} className="d-inline">{notification.user[0]?.username}</Link> Make an Offer for {notification.price} {notification.currency}</h6>}
                                                    {notification.type === 2 && <h6 className='status'><Link to={`/artist/${notification.user[0]?.username}`} className="d-inline">{notification.user[0]?.username}</Link> Bids for {notification.price} {notification.currency}</h6>}
                                                    {notification.type === 3 && <h6 className='status'><Link to={`/artist/${notification.user[0]?.username}`} className="d-inline">{notification.user[0]?.username}</Link> Purchased for {notification.price} {notification.currency}</h6>}
                                                    {notification.type === 4 && <h6 className='status'><Link to={`/artist/${notification.user[0]?.username}`} className="d-inline">{notification.user[0]?.username}</Link> Accepts your offer for {notification.price} {notification.currency}</h6>}
                                                    {notification.type === 5 && <h6 className='status'><Link to={`/artist/${notification.user[0]?.username}`} className="d-inline">{notification.user[0]?.username}</Link> Accepts your bid for {notification.price} {notification.currency}</h6>}

                                                    <p className='time'>{moment(notification.createdAt).fromNow()}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div className="username d-flex align-items-center justify-content-between">
                                    <span className="noti-tag text-center">You don't have any notifications yet.</span>
                                </div>
                            }
                        </InfiniteScroll>
                    </div>
                }
                {/* {
                        loadMoreBtn &&
                        <div className="noti-btn"> 
                            <button onClick={() => loadMore()} >Load more...</button>
                        </div>
                    } */}
            </Modal.Body>
        </Modal>
    )
}
const mapStateToProps = state => ({
    notificationsAuth: state.user.notificationsAuth,
    notifications: state.user.notifications,
    pagination: state.user.notiPagination
});

export default connect(mapStateToProps, { beforeUser, updateNotiCount, getNotifications })(Notifications);
